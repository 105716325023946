// eslint-disable-next-line import/no-extraneous-dependencies
import { createServer, Model, Serializer } from 'miragejs';
import { PAINT_BASE_URL, PAINT_NAMESPACE } from '@/common/services/api.core';
import sites from './fixtures/sites';

const FileMakerSerializer = Serializer.extend({
  root: false,
  embed: true,
  serialize(...args) {
    // This is how to call super, as Mirage borrows [Backbone's implementation of extend](http://backbonejs.org/#Model-extend)
    const json = Serializer.prototype.serialize.apply(this, args);
    const dataInfo = {
      database: 'Site Locator',
      layout: 'SiteAPI',
      table: 'Site',
      totalRecordCount: 4800,
      foundCount: json.length,
      returnedCount: 100,
    };
    const messages = [
      {
        code: '0',
        message: 'OK',
      },
    ];
    return { data: json, dataInfo, messages };
  },
});

export const makeServer = function makeServer({ environment = 'development' } = {}) {
  const server = createServer({
    environment,
    fixtures: {
      sites,
    },
    serializers: {
      application: FileMakerSerializer,
    },
    models: {
      sites: Model,
    },

    seeds(s) {
      s.loadFixtures('sites');
    },
    routes() {
      this.urlPrefix = PAINT_BASE_URL;
      this.namespace = PAINT_NAMESPACE;

      this.get('/layouts/SiteAPI/records', (schema) => schema.sites.all());
      this.post('/layouts/SiteAPI/_find', (schema, request) => {
        const { query: [{ State }] } = request.requestBody;
        return schema.sites.where((site) => site.fieldData.State === State);
      });

      // Mirage doesn't seem to support multiple wildcard paths e.g. `https://*.basemaps.cartocdn.com/**`, so we need to enumerate the ones we're using.
      this.passthrough('https://a.basemaps.cartocdn.com/**', 'https://b.basemaps.cartocdn.com/**', 'https://c.basemaps.cartocdn.com/**', 'https://api.mapbox.com/**', 'https://events.mapbox.com/**', 'https://geo.fcc.gov/**', 'https://a6z4vghb9a.execute-api.us-east-1.amazonaws.com/**');
    },
  });

  return server;
};
export { makeServer as default };
