const sites = [
  {
    fieldData: {
      Preview_Calc: 'Avon Transfer Station (Avon Residents Only)\r281 Huckleberry Hill Rd\rAvon, CT, 06001\r(860) 724-4575\r\rWebsite\rhttp://www.town.avon.ct.us/Public_Documents/AvonCT_DPW/trash\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Avon Transfer Station (Avon Residents Only)',
      Site_Address: '281 Huckleberry Hill Rd',
      City: 'Avon',
      Zip: '06001',
      Phone_Line: '(860) 724-4575',
      URL: 'http://www.town.avon.ct.us/Public_Documents/AvonCT_DPW/trash',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.911308000000005,
      Latitude: 41.789186000000001,
      Pin_Code: '5 Gal',
      Location_ID: '4014',
      Site_ID_6: 'CT1635',
      County: 'Hartford',
    },
    portalData: {},
    recordId: '6355',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Burlington Transfer Station ( Burlington Residents Only)\r66 Belden Rd\rBurlington, CT, 06013\r(860) 673-2439 Ext 224 or (860) 673-6789 Ext 8\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Burlington Transfer Station ( Burlington Residents Only)',
      Site_Address: '66 Belden Rd',
      City: 'Burlington',
      Zip: '06013',
      Phone_Line: '(860) 673-2439 Ext 224 or (860) 673-6789 Ext 8',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.954381900000001,
      Latitude: 41.771626699999999,
      Pin_Code: 'HHW',
      Location_ID: '4592',
      Site_ID_6: 'CT1780',
      County: 'Hartford',
    },
    portalData: {},
    recordId: '6356',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'East Hartford Transfer Station (East Hartford Residents Only)\r61 Ecology Dr\rEast Hartford, CT, 06108\r(860) 282-4845\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'East Hartford Transfer Station (East Hartford Residents Only)',
      Site_Address: '61 Ecology Dr',
      City: 'East Hartford',
      Zip: '06108',
      Phone_Line: '(860) 282-4845',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.62894,
      Latitude: 41.77163,
      Pin_Code: 'HHW',
      Location_ID: '6082',
      Site_ID_6: 'CT2157',
      County: 'Hartford',
    },
    portalData: {},
    recordId: '6357',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Eastford Transfer Station (Eastford Residents Only)\r264 Westford Rd\rEastford, CT, 06242\r(860) 974-0133\r\rWebsite\rhttp://www.townofeastford.org/index.php?option=com_content&view=article&id=126&Itemid=99\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Eastford Transfer Station (Eastford Residents Only)',
      Site_Address: '264 Westford Rd',
      City: 'Eastford',
      Zip: '06242',
      Phone_Line: '(860) 974-0133',
      URL: 'http://www.townofeastford.org/index.php?option=com_content&view=article&id=126&Itemid=99',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.113483000000002,
      Latitude: 41.907366000000003,
      Pin_Code: '5 Gal',
      Location_ID: '4170',
      Site_ID_6: 'CT1682',
      County: '',
    },
    portalData: {},
    recordId: '6358',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Essex Transfer Station (Essex Residents Only)\r5 Dump Rd\rEssex, CT, 06426\r(860) 767-1926\r\rWebsite\rhttp://www.essexct.gov/public-works\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Essex Transfer Station (Essex Residents Only)',
      Site_Address: '5 Dump Rd',
      City: 'Essex',
      Zip: '06426',
      Phone_Line: '(860) 767-1926',
      URL: 'http://www.essexct.gov/public-works',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.422173299999997,
      Latitude: 41.364231799999999,
      Pin_Code: '5 Gal',
      Location_ID: '4020',
      Site_ID_6: 'CT1640',
      County: 'Middlesex',
    },
    portalData: {},
    recordId: '6359',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Granby Transfer Station (Granby Residents Only)\r103 Old Stagecoach Rd\rGranby, CT, 06035\r(860) 653-8960\r\rWebsite\rhttp://www.granby-ct.gov/Public_Documents/GranbyCT_DPW/items\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Granby Transfer Station (Granby Residents Only)',
      Site_Address: '103 Old Stagecoach Rd',
      City: 'Granby',
      Zip: '06035',
      Phone_Line: '(860) 653-8960',
      URL: 'http://www.granby-ct.gov/Public_Documents/GranbyCT_DPW/items',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.791179999999997,
      Latitude: 41.978718000000001,
      Pin_Code: '5 Gal',
      Location_ID: '4021',
      Site_ID_6: 'CT1641',
      County: '',
    },
    portalData: {},
    recordId: '6360',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Hartford Transfer Station (Hartford Residents Only)\r180 Leibert Rd\rHartford, CT, 06120\r\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Hartford Transfer Station (Hartford Residents Only)',
      Site_Address: '180 Leibert Rd',
      City: 'Hartford',
      Zip: '06120',
      Phone_Line: '',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.654728000000006,
      Latitude: 41.789772999999997,
      Pin_Code: '5 Gal',
      Location_ID: '4937',
      Site_ID_6: 'CT1881',
      County: 'Hartford',
    },
    portalData: {},
    recordId: '6361',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Housatonic Resources Recovery Authority HHW Collection Event\rPublic Works Department\r4 Turkey Hill Rd\rNewtown, CT, 06470\r(203) 775-4539\r\rWebsite\rhttp://hrra.org/\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Housatonic Resources Recovery Authority HHW Collection Event',
      Site_Address: '4 Turkey Hill Rd',
      City: 'Newtown',
      Zip: '06470',
      Phone_Line: '(203) 775-4539',
      URL: 'http://hrra.org/',
      State: 'CT',
      Name2: 'Public Works Department',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.278534699999994,
      Latitude: 41.386420200000003,
      Pin_Code: 'HHW',
      Location_ID: '5171',
      Site_ID_6: 'CT1788',
      County: 'Fairfield',
    },
    portalData: {},
    recordId: '6363',
    modId: '72',
  },
  {
    fieldData: {
      Preview_Calc: 'Lebanon Transfer Station (Lebanon Residents Only)\r175 Exeter Rd\rLebanon, CT, 06249\r(860) 642-7901\r\rWebsite\rhttp://www.lebanontownhall.org/department.htm?id=7qd52q3m\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Lebanon Transfer Station (Lebanon Residents Only)',
      Site_Address: '175 Exeter Rd',
      City: 'Lebanon',
      Zip: '06249',
      Phone_Line: '(860) 642-7901',
      URL: 'http://www.lebanontownhall.org/department.htm?id=7qd52q3m',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.183712,
      Latitude: 41.640931999999999,
      Pin_Code: '5 Gal',
      Location_ID: '4029',
      Site_ID_6: 'CT1646',
      County: 'New London',
    },
    portalData: {},
    recordId: '6364',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Montville Transfer Station (Montville Residents Only)\r669 Route 163\rOakdale, CT, 06370\r(860) 848-0401\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Montville Transfer Station (Montville Residents Only)',
      Site_Address: '669 Route 163',
      City: 'Oakdale',
      Zip: '06370',
      Phone_Line: '(860) 848-0401',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.153703,
      Latitude: 41.464144,
      Pin_Code: '5 Gal',
      Location_ID: '6489',
      Site_ID_6: 'CT2051',
      County: 'New London',
    },
    portalData: {},
    recordId: '6365',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'New London Transfer Station (New London Residents Only)\r63 Lewis St\rNew London, CT, 06320\r(860) 447-5248\r\rWebsite\rhttp://www.ci.new-london.ct.us/content/7429/7431/7461/7973.aspx\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'New London Transfer Station (New London Residents Only)',
      Site_Address: '63 Lewis St',
      City: 'New London',
      Zip: '06320',
      Phone_Line: '(860) 447-5248',
      URL: 'http://www.ci.new-london.ct.us/content/7429/7431/7461/7973.aspx',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.095495,
      Latitude: 41.364350999999999,
      Pin_Code: '5 Gal',
      Location_ID: '4035',
      Site_ID_6: 'CT1650',
      County: 'New London',
    },
    portalData: {},
    recordId: '6366',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Redding Transfer Station ( Redding Residents Only)\r84 Hopewell Woods Rd\rRedding Center, CT, 06875\r(203) 938-3026\r\rWebsite\rhttp://www.townofreddingct.org/public_documents/reddingct_recycle/index\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Redding Transfer Station ( Redding Residents Only)',
      Site_Address: '84 Hopewell Woods Rd',
      City: 'Redding Center',
      Zip: '06875',
      Phone_Line: '(203) 938-3026',
      URL: 'http://www.townofreddingct.org/public_documents/reddingct_recycle/index',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.346656999999993,
      Latitude: 41.332622000000001,
      Pin_Code: '5 Gal',
      Location_ID: '4038',
      Site_ID_6: 'CT1653',
      County: 'Fairfield',
    },
    portalData: {},
    recordId: '6367',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Watertown Transfer Station ( Watertown residents only)\rOld Baird Rd\rWatertown, CT, 06795\r(860) 945-5244\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Watertown Transfer Station ( Watertown residents only)',
      Site_Address: 'Old Baird Rd',
      City: 'Watertown',
      Zip: '06795',
      Phone_Line: '(860) 945-5244',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.137984000000003,
      Latitude: 41.590998999999996,
      Pin_Code: '5 Gal',
      Location_ID: '5007',
      Site_ID_6: 'CT1757',
      County: '',
    },
    portalData: {},
    recordId: '6368',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: '44 Hardware\r320 W Main St\rAvon, CT, 06001\r(860) 678-8665\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: '44 Hardware',
      Site_Address: '320 W Main St',
      City: 'Avon',
      Zip: '06001',
      Phone_Line: '(860) 678-8665',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.858236099999999,
      Latitude: 41.814950199999998,
      Pin_Code: '5 Gal',
      Location_ID: '3783',
      Site_ID_6: 'CT2004',
      County: 'Hartford',
    },
    portalData: {},
    recordId: '6369',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Ace Hardware\r690 Main St S\rWoodbury, CT, 06798\r(203) 263-5999\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Ace Hardware',
      Site_Address: '690 Main St S',
      City: 'Woodbury',
      Zip: '06798',
      Phone_Line: '(203) 263-5999',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.204916800000007,
      Latitude: 41.527048600000001,
      Pin_Code: '5 Gal',
      Location_ID: '3841',
      Site_ID_6: 'CT1084',
      County: 'Litchfield',
    },
    portalData: {},
    recordId: '6372',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Ace Home Center\r480 S Main St\rMiddletown, CT, 06457\r(860) 347-6588\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Ace Home Center',
      Site_Address: '480 S Main St',
      City: 'Middletown',
      Zip: '06457',
      Phone_Line: '(860) 347-6588',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.651010600000006,
      Latitude: 41.542788999999999,
      Pin_Code: '5 Gal',
      Location_ID: '3863',
      Site_ID_6: 'CT1038',
      County: 'Middlesex',
    },
    portalData: {},
    recordId: '6373',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Ace Home Center\r146 W Town St\rNorwich, CT, 06360\r(860) 889-3853\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Ace Home Center',
      Site_Address: '146 W Town St',
      City: 'Norwich',
      Zip: '06360',
      Phone_Line: '(860) 889-3853',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.1072420999999,
      Latitude: 41.55306089999999,
      Pin_Code: '5 Gal',
      Location_ID: '3811',
      Site_ID_6: 'CT1054',
      County: 'New London',
    },
    portalData: {},
    recordId: '6374',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Back To Basics True Value\r31 Main St\rTerryville, CT, 06786\r(860) 589-8858\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Back To Basics True Value',
      Site_Address: '31 Main St',
      City: 'Terryville',
      Zip: '06786',
      Phone_Line: '(860) 589-8858',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.994412,
      Latitude: 41.679385,
      Pin_Code: '5 Gal',
      Location_ID: '7009',
      Site_ID_6: 'CT2137',
      County: 'Litchfield',
    },
    portalData: {},
    recordId: '6375',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Branford Building Supplies\r1145 Main St\rBranford, CT, 06405\r(203) 488-2518\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Branford Building Supplies',
      Site_Address: '1145 Main St',
      City: 'Branford',
      Zip: '06405',
      Phone_Line: '(203) 488-2518',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.809320099999994,
      Latitude: 41.283029999999997,
      Pin_Code: '5 Gal',
      Location_ID: '4716',
      Site_ID_6: 'CT1106',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6377',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Bristol Transfer Station (Bristol Residents Only)\r685 Lake Ave\rBristol, CT, 06010\r(860) 584-7791\r\rWebsite\rhttp://www.ci.bristol.ct.us/index.aspx?NID=237\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Bristol Transfer Station (Bristol Residents Only)',
      Site_Address: '685 Lake Ave',
      City: 'Bristol',
      Zip: '06010',
      Phone_Line: '(860) 584-7791',
      URL: 'http://www.ci.bristol.ct.us/index.aspx?NID=237',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.923801999999995,
      Latitude: 41.649406999999997,
      Pin_Code: '5 Gal',
      Location_ID: '4017',
      Site_ID_6: 'CT1638',
      County: 'Hartford',
    },
    portalData: {},
    recordId: '6379',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Brooklyn Transfer Station (Brooklyn residents only)\r95 Ruckstella Rd\rBrooklyn, CT, 06234\r(860) 779-1105\r\rWebsite\rhttps://www.brooklynct.org/quick-view/149\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Brooklyn Transfer Station (Brooklyn residents only)',
      Site_Address: '95 Ruckstella Rd',
      City: 'Brooklyn',
      Zip: '06234',
      Phone_Line: '(860) 779-1105',
      URL: 'https://www.brooklynct.org/quick-view/149',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -71.941388000000003,
      Latitude: 41.752457999999997,
      Pin_Code: '5 Gal',
      Location_ID: '4333',
      Site_ID_6: 'CT1770',
      County: 'Windham',
    },
    portalData: {},
    recordId: '6380',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'C A Lindell True Value Hardware and Lumber\r59 Church St\rCanaan, CT, 06018\r(860) 824-5443\r\rWebsite\rhttp://www.truevalue.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'C A Lindell True Value Hardware and Lumber',
      Site_Address: '59 Church St',
      City: 'Canaan',
      Zip: '06018',
      Phone_Line: '(860) 824-5443',
      URL: 'http://www.truevalue.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.334726500000002,
      Latitude: 42.026579300000002,
      Pin_Code: '5 Gal',
      Location_ID: '3791',
      Site_ID_6: 'CT1016',
      County: 'Litchfield',
    },
    portalData: {},
    recordId: '6382',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Canaan Transfer Station\r100 Route 63\rFalls Village, CT, 06031\r(860) 824-5819\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Canaan Transfer Station',
      Site_Address: '100 Route 63',
      City: 'Falls Village',
      Zip: '06031',
      Phone_Line: '(860) 824-5819',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: ' Huntsville South',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.328806,
      Latitude: 41.950047,
      Pin_Code: '5 Gal',
      Location_ID: '7080',
      Site_ID_6: 'CT2197',
      County: 'Litchfield',
    },
    portalData: {},
    recordId: '6383',
    modId: '74',
  },
  {
    fieldData: {
      Preview_Calc: 'Canterbury Transfer Station (Canterbury Residents Only)\r111 Kinne Rd\rCanterbury, CT, 06331\r(860) 546-6604\r\rWebsite\rhttp://www.canterburyct.org/department.php?id=11\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Canterbury Transfer Station (Canterbury Residents Only)',
      Site_Address: '111 Kinne Rd',
      City: 'Canterbury',
      Zip: '06331',
      Phone_Line: '(860) 546-6604',
      URL: 'http://www.canterburyct.org/department.php?id=11',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.008398,
      Latitude: 41.687524000000003,
      Pin_Code: '5 Gal',
      Location_ID: '4018',
      Site_ID_6: 'CT1639',
      County: 'Windham',
    },
    portalData: {},
    recordId: '6384',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Carr Hardware\r481 Enfield St\rEnfield, CT, 06082\r(860) 265-3901\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Carr Hardware',
      Site_Address: '481 Enfield St',
      City: 'Enfield',
      Zip: '06082',
      Phone_Line: '(860) 265-3901',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.591872600000002,
      Latitude: 42.011332899999999,
      Pin_Code: '5 Gal',
      Location_ID: '6947',
      Site_ID_6: 'CT2014',
      County: 'Hartford',
    },
    portalData: {},
    recordId: '6386',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Carr Hardware\r320 W Main St\rAvon, CT, 06001\r(860) 470-3053\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Carr Hardware',
      Site_Address: '320 W Main St',
      City: 'Avon',
      Zip: '06001',
      Phone_Line: '(860) 470-3053',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.858236099999999,
      Latitude: 41.814950199999998,
      Pin_Code: '5 Gal',
      Location_ID: '6155',
      Site_ID_6: 'CT2004',
      County: 'Hartford',
    },
    portalData: {},
    recordId: '6387',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Cash True Value Home Center\r1561 Main St\rWillimantic, CT, 06226\r(860) 423-1649\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Cash True Value Home Center',
      Site_Address: '1561 Main St',
      City: 'Willimantic',
      Zip: '06226',
      Phone_Line: '(860) 423-1649',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.236065199999999,
      Latitude: 41.720278700000001,
      Pin_Code: '5 Gal',
      Location_ID: '4165',
      Site_ID_6: 'CT1584',
      County: 'Windham',
    },
    portalData: {},
    recordId: '6388',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Cash True Value Home Center\r300 Flanders Rd\rEast Lyme, CT, 06333\r(860) 739-5496\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Cash True Value Home Center',
      Site_Address: '300 Flanders Rd',
      City: 'East Lyme',
      Zip: '06333',
      Phone_Line: '(860) 739-5496',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.210047200000005,
      Latitude: 41.363162099999997,
      Pin_Code: '5 Gal',
      Location_ID: '4167',
      Site_ID_6: 'CT1204',
      County: 'New London',
    },
    portalData: {},
    recordId: '6389',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Cash True Value Home Ctr\r9 Hendel Dr\rMystic, CT, 06355\r(860) 536-9601\r\rWebsite\rhttp://www.truevalue.com\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Cash True Value Home Ctr',
      Site_Address: '9 Hendel Dr',
      City: 'Mystic',
      Zip: '06355',
      Phone_Line: '(860) 536-9601',
      URL: 'http://www.truevalue.com',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -71.959331000000006,
      Latitude: 41.376984100000001,
      Pin_Code: '5 Gal',
      Location_ID: '4172',
      Site_ID_6: 'CT1330',
      County: 'New London',
    },
    portalData: {},
    recordId: '6390',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Chatfield True Value Hardware\r190 Main St S\rSouthbury, CT, 06488\r(203) 264-4775\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Chatfield True Value Hardware',
      Site_Address: '190 Main St S',
      City: 'Southbury',
      Zip: '06488',
      Phone_Line: '(203) 264-4775',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.218973800000001,
      Latitude: 41.476199399999999,
      Pin_Code: '5 Gal',
      Location_ID: '7073',
      Site_ID_6: 'CT1468',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6391',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'City Hardware\r750 Farmington Ave\rBristol, CT, 06010\r(860) 582-6366\r\rWebsite\rhttp://www.truevalue.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'City Hardware',
      Site_Address: '750 Farmington Ave',
      City: 'Bristol',
      Zip: '06010',
      Phone_Line: '(860) 582-6366',
      URL: 'http://www.truevalue.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.918697699999996,
      Latitude: 41.691697300000001,
      Pin_Code: '5 Gal',
      Location_ID: '3789',
      Site_ID_6: 'CT1689',
      County: 'Hartford',
    },
    portalData: {},
    recordId: '6393',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Clinton Paint & Decorating\r252 E Main St\rRte 1\rClinton, CT, 06413\r(860) 669-8842\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Clinton Paint & Decorating',
      Site_Address: '252 E Main St',
      City: 'Clinton',
      Zip: '06413',
      Phone_Line: '(860) 669-8842',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: 'Rte 1',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.5010482,
      Latitude: 41.271045399999998,
      Pin_Code: '5 Gal',
      Location_ID: '6518',
      Site_ID_6: 'CT1146',
      County: 'Middlesex',
    },
    portalData: {},
    recordId: '6397',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Colony Paint\r282 Boston Post Rd\rMilford, CT, 06460\r(203) 874-8014\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Colony Paint',
      Site_Address: '282 Boston Post Rd',
      City: 'Milford',
      Zip: '06460',
      Phone_Line: '(203) 874-8014',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.073190800000006,
      Latitude: 41.223173299999999,
      Pin_Code: '5 Gal',
      Location_ID: '4169',
      Site_ID_6: 'CT1317',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6398',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Cornwall Transfer Station (Cornwall Residents Only)\r24 Furnace Brook Rd\rCornwall, CT, 06753\r(860) 672-4079\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Cornwall Transfer Station (Cornwall Residents Only)',
      Site_Address: '24 Furnace Brook Rd',
      City: 'Cornwall',
      Zip: '06753',
      Phone_Line: '(860) 672-4079',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.332892000000001,
      Latitude: 41.846732000000003,
      Pin_Code: '5 Gal',
      Location_ID: '5595',
      Site_ID_6: 'CT1771',
      County: 'Litchfield',
    },
    portalData: {},
    recordId: '6400',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Country Paint And Hardware Inc\r2410 Foxon Rd\rNorth Branford, CT, 06471\r(203) 481-5255\r\rWebsite\rhttp://www.countrypaint.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Country Paint And Hardware Inc',
      Site_Address: '2410 Foxon Rd',
      City: 'North Branford',
      Zip: '06471',
      Phone_Line: '(203) 481-5255',
      URL: 'http://www.countrypaint.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.749162400000003,
      Latitude: 41.342394300000002,
      Pin_Code: '5 Gal',
      Location_ID: '3823',
      Site_ID_6: 'CT1049',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6401',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Darien Transfer Station (Darien households only)\r126 Ledge Rd\rDarien, CT, 06820\r(203) 656-7340\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Darien Transfer Station (Darien households only)',
      Site_Address: '126 Ledge Rd',
      City: 'Darien',
      Zip: '06820',
      Phone_Line: '(203) 656-7340',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.483532999999994,
      Latitude: 41.071795000000002,
      Pin_Code: '5 Gal',
      Location_ID: '5582',
      Site_ID_6: 'CT1920',
      County: 'Fairfield',
    },
    portalData: {},
    recordId: '6402',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'East Hampton Ace Hardware\r25 E High St\rEast Hampton, CT, 06424\r(860) 267-7943\r\rWebsite\rhttp://www.acehardware.com\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'East Hampton Ace Hardware',
      Site_Address: '25 E High St',
      City: 'East Hampton',
      Zip: '06424',
      Phone_Line: '(860) 267-7943',
      URL: 'http://www.acehardware.com',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.502791799999997,
      Latitude: 41.584147399999999,
      Pin_Code: '5 Gal',
      Location_ID: '3819',
      Site_ID_6: 'CT1021',
      County: 'Middlesex',
    },
    portalData: {},
    recordId: '6404',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Ed Herringtons Inc\r11 Farnham Rd\rLakeville, CT, 06039\r(860) 435-2561\r\rWebsite\rhttp://www.herringtons.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Ed Herringtons Inc',
      Site_Address: '11 Farnham Rd',
      City: 'Lakeville',
      Zip: '06039',
      Phone_Line: '(860) 435-2561',
      URL: 'http://www.herringtons.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.43880,
      Latitude: 41.963445,
      Pin_Code: '5 Gal',
      Location_ID: '4119',
      Site_ID_6: 'CT1279',
      County: 'Litchfield',
    },
    portalData: {},
    recordId: '6405',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'England True Value Hardware\r661 Boston Turnpike\rRte 44\rBolton, CT, 06043\r(860) 649-1320\r\rWebsite\rhttp://www.truevalue.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'England True Value Hardware',
      Site_Address: '661 Boston Turnpike',
      City: 'Bolton',
      Zip: '06043',
      Phone_Line: '(860) 649-1320',
      URL: 'http://www.truevalue.com/',
      State: 'CT',
      Name2: '',
      Address2: 'Rte 44',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.442474500000003,
      Latitude: 41.791083,
      Pin_Code: '5 Gal',
      Location_ID: '4196',
      Site_ID_6: 'CT1105',
      County: 'Litchfield',
    },
    portalData: {},
    recordId: '6406',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Hamden Transfer Station (Hamden Residents Only)\r231 Wintergreen Ave\rHamden, CT, 06514\r(203) 387-2435\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Hamden Transfer Station (Hamden Residents Only)',
      Site_Address: '231 Wintergreen Ave',
      City: 'Hamden',
      Zip: '06514',
      Phone_Line: '(203) 387-2435',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.952697999999998,
      Latitude: 41.357579000000001,
      Pin_Code: '5 Gal',
      Location_ID: '6134',
      Site_ID_6: 'CT1994',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6413',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Holdridge Home and Garden True Value Hardware\r749 Colonel Ledyard Hwy\rLedyard, CT, 06339\r(860) 464-8400\r\rWebsite\rhttp://www.truevalue.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Holdridge Home and Garden True Value Hardware',
      Site_Address: '749 Colonel Ledyard Hwy',
      City: 'Ledyard',
      Zip: '06339',
      Phone_Line: '(860) 464-8400',
      URL: 'http://www.truevalue.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.018348599999996,
      Latitude: 41.443600199999999,
      Pin_Code: '5 Gal',
      Location_ID: '3860',
      Site_ID_6: 'CT1033',
      County: 'New London',
    },
    portalData: {},
    recordId: '6416',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Hope Hardware & Plumbing Supply\r212 Old Hartford Rd\rColchester, CT, 06415\r(860) 537-3046\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Hope Hardware & Plumbing Supply',
      Site_Address: '212 Old Hartford Rd',
      City: 'Colchester',
      Zip: '06415',
      Phone_Line: '(860) 537-3046',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.345619999999997,
      Latitude: 41.580328000000002,
      Pin_Code: '5 Gal',
      Location_ID: '7067',
      Site_ID_6: 'CT1149',
      County: 'New London',
    },
    portalData: {},
    recordId: '6417',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'John Boyle Co\r152 S Main St\rNew Britain, CT, 06051\r(860) 224-2436\r\rWebsite\rhttp://www.johnboyleco.benmoorepaints.com/home/home/sb.cn\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'John Boyle Co',
      Site_Address: '152 S Main St',
      City: 'New Britain',
      Zip: '06051',
      Phone_Line: '(860) 224-2436',
      URL: 'http://www.johnboyleco.benmoorepaints.com/home/home/sb.cn',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.778349,
      Latitude: 41.714008999999997,
      Pin_Code: '5 Gal',
      Location_ID: '3796',
      Site_ID_6: 'CT1043',
      County: 'Hartford',
    },
    portalData: {},
    recordId: '6422',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Johnson True Value Hardware\r40 Fort Hill Rd\rGroton, CT, 06340\r(860) 448-1760\r\rWebsite\rhttp://www.johnsonstruevalue.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Johnson True Value Hardware',
      Site_Address: '40 Fort Hill Rd',
      City: 'Groton',
      Zip: '06340',
      Phone_Line: '(860) 448-1760',
      URL: 'http://www.johnsonstruevalue.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.034743599999999,
      Latitude: 41.346430300000002,
      Pin_Code: '5 Gal',
      Location_ID: '4012',
      Site_ID_6: 'CT1028',
      County: 'New London',
    },
    portalData: {},
    recordId: '6424',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Katz Hardware\r2687 Main St\rGlastonbury, CT, 06033\r(860) 633-3551\r\rWebsite\rhttp://www.katzhardware.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Katz Hardware',
      Site_Address: '2687 Main St',
      City: 'Glastonbury',
      Zip: '06033',
      Phone_Line: '(860) 633-3551',
      URL: 'http://www.katzhardware.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.611957599999997,
      Latitude: 41.715966100000003,
      Pin_Code: '5 Gal',
      Location_ID: '3792',
      Site_ID_6: 'CT1024',
      County: 'Hartford',
    },
    portalData: {},
    recordId: '6425',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Kent Transfer Station (Kent Residence Only)\r46 Maple St\rKent, CT, 06757\r\r\rWebsite\rhttp://www.townofkentct.org/\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Kent Transfer Station (Kent Residence Only)',
      Site_Address: '46 Maple St',
      City: 'Kent',
      Zip: '06757',
      Phone_Line: '',
      URL: 'http://www.townofkentct.org/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.475876,
      Latitude: 41.723770999999999,
      Pin_Code: '5 Gal',
      Location_ID: '4936',
      Site_ID_6: 'CT1886',
      County: 'Litchfield',
    },
    portalData: {},
    recordId: '6426',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Keoughs Paint and Hardware\r1 Ethan Allen Hwy\rRidgefield, CT, 06877\r(203) 544-8379\r\rWebsite\rhttp://www.keoughs.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Keoughs Paint and Hardware',
      Site_Address: '1 Ethan Allen Hwy',
      City: 'Ridgefield',
      Zip: '06877',
      Phone_Line: '(203) 544-8379',
      URL: 'http://www.keoughs.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.440537800000001,
      Latitude: 41.2644813,
      Pin_Code: '5 Gal',
      Location_ID: '3855',
      Site_ID_6: 'CT1060',
      County: 'Fairfield',
    },
    portalData: {},
    recordId: '6428',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Killingworth True Value Hardware\r228 Route 81\rKillingworth, CT, 06419\r(860) 663-2531\r\rWebsite\rhttp://www.truevalue.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Killingworth True Value Hardware',
      Site_Address: '228 Route 81',
      City: 'Killingworth',
      Zip: '06419',
      Phone_Line: '(860) 663-2531',
      URL: 'http://www.truevalue.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.564867599999999,
      Latitude: 41.355319399999999,
      Pin_Code: '5 Gal',
      Location_ID: '3818',
      Site_ID_6: 'CT1032',
      County: 'Middlesex',
    },
    portalData: {},
    recordId: '6429',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Larsen Ace Hardware\r220 Albany Turnpike\rCanton, CT, 06019\r(860) 693-4618\r\rWebsite\rhttp://www.acehardware.com\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Larsen Ace Hardware',
      Site_Address: '220 Albany Turnpike',
      City: 'Canton',
      Zip: '06019',
      Phone_Line: '(860) 693-4618',
      URL: 'http://www.acehardware.com',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.899940200000003,
      Latitude: 41.825489900000001,
      Pin_Code: '5 Gal',
      Location_ID: '4347',
      Site_ID_6: 'CT1139',
      County: 'Hartford',
    },
    portalData: {},
    recordId: '6430',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Ledyard Transfer Station (Ledyard Residents Only)\r889 Colonel Ledyard Hwy\rLedyard, CT, 06339\r(860) 464-9060\r\rWebsite\rhttp://www.town.ledyard.ct.us/191/Transfer-Station\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\r\r',
      Count: 162,
      Active: 'Y',
      Name: 'Ledyard Transfer Station (Ledyard Residents Only)',
      Site_Address: '889 Colonel Ledyard Hwy',
      City: 'Ledyard',
      Zip: '06339',
      Phone_Line: '(860) 464-9060',
      URL: 'http://www.town.ledyard.ct.us/191/Transfer-Station',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: '',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.017375,
      Latitude: 41.460375,
      Pin_Code: '5 Gal',
      Location_ID: '4938',
      Site_ID_6: 'CT1666',
      County: 'New London',
    },
    portalData: {},
    recordId: '6432',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Lower Connecticut River Valley Council Household Hazardous Waste 2016 Collection Events\rRiverCOG HHW Facility\rDump Rd\r11 Dump Rd\rEssex, CT, 06426\r(860) 581-8554\r\rWebsite\rhttp://www.rivercog.org/hhw.html\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Lower Connecticut River Valley Council Household Hazardous Waste 2016 Collection Events',
      Site_Address: 'Dump Rd',
      City: 'Essex',
      Zip: '06426',
      Phone_Line: '(860) 581-8554',
      URL: 'http://www.rivercog.org/hhw.html',
      State: 'CT',
      Name2: 'RiverCOG HHW Facility',
      Address2: '11 Dump Rd',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -68.960851000000005,
      Latitude: 44.455681800000001,
      Pin_Code: 'HHW',
      Location_ID: '4142',
      Site_ID_6: 'CT1686',
      County: 'Middlesex',
    },
    portalData: {},
    recordId: '6433',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Mahers Paint and Wallpaper LLC\r369 W Main St\rAvon, CT, 06001\r(860) 678-1200\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Mahers Paint and Wallpaper LLC',
      Site_Address: '369 W Main St',
      City: 'Avon',
      Zip: '06001',
      Phone_Line: '(860) 678-1200',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.862939999999995,
      Latitude: 41.814881,
      Pin_Code: '5 Gal',
      Location_ID: '3785',
      Site_ID_6: 'CT1002',
      County: 'Hartford',
    },
    portalData: {},
    recordId: '6436',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Mansfield Supply Inc\r1527 Storrs Rd\rStorrs, CT, 06268\r(860) 429-2990\r\rWebsite\rhttp://www.mansfieldsupply.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Mansfield Supply Inc',
      Site_Address: '1527 Storrs Rd',
      City: 'Storrs',
      Zip: '06268',
      Phone_Line: '(860) 429-2990',
      URL: 'http://www.mansfieldsupply.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.258466600000006,
      Latitude: 41.820331199999998,
      Pin_Code: '5 Gal',
      Location_ID: '3808',
      Site_ID_6: 'CT1067',
      County: 'Tolland',
    },
    portalData: {},
    recordId: '6438',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Mansfield Transfer Station (Mansfield Residents Only)\r221 Warrenville Rd\rMansfield Center, CT, 06250\r(860) 429-7189\r\rWebsite\rhttp://www.mansfieldct.gov/content/1914/3973/4005/4020.aspx\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Mansfield Transfer Station (Mansfield Residents Only)',
      Site_Address: '221 Warrenville Rd',
      City: 'Mansfield Center',
      Zip: '06250',
      Phone_Line: '(860) 429-7189',
      URL: 'http://www.mansfieldct.gov/content/1914/3973/4005/4020.aspx',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.188845999999998,
      Latitude: 41.778742999999999,
      Pin_Code: '5 Gal',
      Location_ID: '4030',
      Site_ID_6: 'CT1647',
      County: 'Tolland',
    },
    portalData: {},
    recordId: '6439',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Morris Transfer Station ( Morris Residents Only)\r254 Lakeside Rd\rMorris, CT, 06763\r(860) 567-7438\r\rWebsite\rhttp://morris.webtownhall.com/Departments/TransferStation/tabid/89/Default.aspx\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Morris Transfer Station ( Morris Residents Only)',
      Site_Address: '254 Lakeside Rd',
      City: 'Morris',
      Zip: '06763',
      Phone_Line: '(860) 567-7438',
      URL: 'http://morris.webtownhall.com/Departments/TransferStation/tabid/89/Default.aspx',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.234230999999994,
      Latitude: 41.682783000000001,
      Pin_Code: '5 Gal',
      Location_ID: '4032',
      Site_ID_6: 'CT1648',
      County: 'Litchfield',
    },
    portalData: {},
    recordId: '6440',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Mystic Paint and Decorating Center\r50 Williams Ave\rMystic, CT, 06355\r(860) 572-7031\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Mystic Paint and Decorating Center',
      Site_Address: '50 Williams Ave',
      City: 'Mystic',
      Zip: '06355',
      Phone_Line: '(860) 572-7031',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -71.957592899999995,
      Latitude: 41.349026000000002,
      Pin_Code: '5 Gal',
      Location_ID: '3800',
      Site_ID_6: 'CT1040',
      County: 'New London',
    },
    portalData: {},
    recordId: '6442',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'New Britain Recycling Center (New Britian & Berlin Residents Only)\r642 Christian Ln\rBerlin, CT, 06037\r(860) 826-3494\r\rWebsite\rhttp://www.brrfoc.org/towns/new-britain\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'New Britain Recycling Center (New Britian & Berlin Residents Only)',
      Site_Address: '642 Christian Ln',
      City: 'Berlin',
      Zip: '06037',
      Phone_Line: '(860) 826-3494',
      URL: 'http://www.brrfoc.org/towns/new-britain',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.751292199999995,
      Latitude: 41.648321500000002,
      Pin_Code: 'HHW',
      Location_ID: '4175',
      Site_ID_6: 'CT1712',
      County: '',
    },
    portalData: {},
    recordId: '6443',
    modId: '78',
  },
  {
    fieldData: {
      Preview_Calc: 'Newtown Hardware\r61 Church Hill Rd\rNewtown, CT, 06470\r(203) 426-4076\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Newtown Hardware',
      Site_Address: '61 Church Hill Rd',
      City: 'Newtown',
      Zip: '06470',
      Phone_Line: '(203) 426-4076',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.295436100000003,
      Latitude: 41.417993000000003,
      Pin_Code: '5 Gal',
      Location_ID: '4775',
      Site_ID_6: 'CT1371',
      County: 'Fairfield',
    },
    portalData: {},
    recordId: '6444',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'North Haven Paint and Hardware\r87 Quinnipiac Ave\rNorth Haven, CT, 06473\r(203) 776-6256\r\rWebsite\rhttp://paintstore.benjaminmoore.com/stores/us/ct/north-haven/north-haven-paint-hardware\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'North Haven Paint and Hardware',
      Site_Address: '87 Quinnipiac Ave',
      City: 'North Haven',
      Zip: '06473',
      Phone_Line: '(203) 776-6256',
      URL: 'http://paintstore.benjaminmoore.com/stores/us/ct/north-haven/north-haven-paint-hardware',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.862968699999996,
      Latitude: 41.341343000000002,
      Pin_Code: '5 Gal',
      Location_ID: '3824',
      Site_ID_6: 'CT1050',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6445',
    modId: '75',
  },
  {
    fieldData: {
      Preview_Calc: 'North Stonington Transfer Station (North Stonington Residents Only)\r191 Wintechog Hill Rd\rNorth Stonington, CT, 06359\r(860) 535-1048\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'North Stonington Transfer Station (North Stonington Residents Only)',
      Site_Address: '191 Wintechog Hill Rd',
      City: 'North Stonington',
      Zip: '06359',
      Phone_Line: '(860) 535-1048',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -71.938373999999996,
      Latitude: 41.462649999999996,
      Pin_Code: '5 Gal',
      Location_ID: '4344',
      Site_ID_6: 'CT1671',
      County: '',
    },
    portalData: {},
    recordId: '6446',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Norwich Transfer Station (Norwich Residents Only)\r73 Rogers Rd\rNorwich, CT, 06360\r(860) 823-3796\r\rWebsite\rhttp://www.norwichct.org/index.aspx?NID=416\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Norwich Transfer Station (Norwich Residents Only)',
      Site_Address: '73 Rogers Rd',
      City: 'Norwich',
      Zip: '06360',
      Phone_Line: '(860) 823-3796',
      URL: 'http://www.norwichct.org/index.aspx?NID=416',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.118932000000001,
      Latitude: 41.541021999999998,
      Pin_Code: '5 Gal',
      Location_ID: '4325',
      Site_ID_6: 'CT1651',
      County: '',
    },
    portalData: {},
    recordId: '6447',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'O L Willard Co\r196 Valley St\rWillimantic, CT, 06226\r(860) 423-1666\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'O L Willard Co',
      Site_Address: '196 Valley St',
      City: 'Willimantic',
      Zip: '06226',
      Phone_Line: '(860) 423-1666',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.2144476,
      Latitude: 41.713900500000001,
      Pin_Code: '5 Gal',
      Location_ID: '4674',
      Site_ID_6: 'CT1583',
      County: 'Windham',
    },
    portalData: {},
    recordId: '6448',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Orange Transfer Station (Orange Residents Only)\r100 S Orange Center Rd\rOrange, CT, 06477\r(203) 891-2177\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Orange Transfer Station (Orange Residents Only)',
      Site_Address: '100 S Orange Center Rd',
      City: 'Orange',
      Zip: '06477',
      Phone_Line: '(203) 891-2177',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.028469999999999,
      Latitude: 41.282963000000002,
      Pin_Code: '5 Gal',
      Location_ID: '4640',
      Site_ID_6: 'CT1845',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6450',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Oxford Household Hazardous Collection Event\rQuaker Farms School\r30 Great Oak Rd\rOxford, CT, 06478\r(203) 757-0535\r\rWebsite\rhttp://www.ct.gov/deep/cwp/view.asp?a=2718&q=325448&deepNav_GID=1646\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Oxford Household Hazardous Collection Event',
      Site_Address: '30 Great Oak Rd',
      City: 'Oxford',
      Zip: '06478',
      Phone_Line: '(203) 757-0535',
      URL: 'http://www.ct.gov/deep/cwp/view.asp?a=2718&q=325448&deepNav_GID=1646',
      State: 'CT',
      Name2: 'Quaker Farms School',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.142626000000007,
      Latitude: 41.426814,
      Pin_Code: 'HHW',
      Location_ID: '5164',
      Site_ID_6: 'CT1912',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6451',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Oxford Lumber and Building Materials\r113 Oxford Rd\rOxford, CT, 06478\r(203) 888-9200\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Oxford Lumber and Building Materials',
      Site_Address: '113 Oxford Rd',
      City: 'Oxford',
      Zip: '06478',
      Phone_Line: '(203) 888-9200',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.095905000000002,
      Latitude: 41.407683800000001,
      Pin_Code: '5 Gal',
      Location_ID: '4717',
      Site_ID_6: 'CT1423',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6452',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Page Hardware and Appliances\r9 Boston St\rGuilford, CT, 06437\r(203) 453-5267\r\rWho Can Use This Program \rFor Households and Businesses\r\rHours \rPaint is accepted during regular business hours. Call ahead for hours and to make sure the store can accept the amount and type of paint you would like to recycle.\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Page Hardware and Appliances',
      Site_Address: '9 Boston St',
      City: 'Guilford',
      Zip: '06437',
      Phone_Line: '(203) 453-5267',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': 'Paint is accepted during regular business hours. Call ahead for hours and to make sure the store can accept the amount and type of paint you would like to recycle.',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.681176300000004,
      Latitude: 41.2813844,
      Pin_Code: '5 Gal',
      Location_ID: '5708',
      Site_ID_6: 'CT1256',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6453',
    modId: '83',
  },
  {
    fieldData: {
      Preview_Calc: 'Plymouth Transfer Station (Plymouth Residents Only)\r175 Old Waterbury Rd\rTerryville, CT, 06786\r(860) 589-7376\r\rWebsite\rhttp://www.plymouthct.us/Transfer-Station/\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Plymouth Transfer Station (Plymouth Residents Only)',
      Site_Address: '175 Old Waterbury Rd',
      City: 'Terryville',
      Zip: '06786',
      Phone_Line: '(860) 589-7376',
      URL: 'http://www.plymouthct.us/Transfer-Station/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.000337000000002,
      Latitude: 41.661200999999998,
      Pin_Code: '5 Gal',
      Location_ID: '4223',
      Site_ID_6: 'CT1688',
      County: 'Litchfield',
    },
    portalData: {},
    recordId: '6461',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Portland Transfer Station (Portland Residents Only)\r33 Sand Hill Rd\rPortland, CT, 06480\r(860) 342-6733\r\rWebsite\rhttp://www.portlandct.org/Portals/12/Departments/Public%20Works/PDF/PortlandRecycles2014.pdf\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Portland Transfer Station (Portland Residents Only)',
      Site_Address: '33 Sand Hill Rd',
      City: 'Portland',
      Zip: '06480',
      Phone_Line: '(860) 342-6733',
      URL: 'http://www.portlandct.org/Portals/12/Departments/Public%20Works/PDF/PortlandRecycles2014.pdf',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.612835000000004,
      Latitude: 41.573054999999997,
      Pin_Code: '5 Gal',
      Location_ID: '4037',
      Site_ID_6: 'CT1652',
      County: 'Middlesex',
    },
    portalData: {},
    recordId: '6462',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'PPG Paints\r1225 Thomaston Ave\rWaterbury, CT, 06704\r(203) 753-1131\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'PPG Paints',
      Site_Address: '1225 Thomaston Ave',
      City: 'Waterbury',
      Zip: '06704',
      Phone_Line: '(203) 753-1131',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.0492816,
      Latitude: 41.582016000000003,
      Pin_Code: '5 Gal',
      Location_ID: '4152',
      Site_ID_6: 'CT1535',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6463',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'PPG Paints\r106 Main St\rNorwalk, CT, 06851\r(203) 750-9553\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\r\r',
      Count: 162,
      Active: 'Y',
      Name: 'PPG Paints',
      Site_Address: '106 Main St',
      City: 'Norwalk',
      Zip: '06851',
      Phone_Line: '(203) 750-9553',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: '',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -68.289175999999998,
      Latitude: 44.294051199999998,
      Pin_Code: '5 Gal',
      Location_ID: '4107',
      Site_ID_6: 'ME1064',
      County: 'Fairfield',
    },
    portalData: {},
    recordId: '6464',
    modId: '66',
  },
  {
    fieldData: {
      Preview_Calc: 'PPG Paints\r1739 Main St\rBridgeport, CT, 06604\r(203) 334-2112\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'PPG Paints',
      Site_Address: '1739 Main St',
      City: 'Bridgeport',
      Zip: '06604',
      Phone_Line: '(203) 334-2112',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.194624300000001,
      Latitude: 41.187243799999997,
      Pin_Code: '5 Gal',
      Location_ID: '4111',
      Site_ID_6: 'CT1115',
      County: 'Fairfield',
    },
    portalData: {},
    recordId: '6467',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'PPG Paints\r320 Queen St\rSouthington, CT, 06489\r(860) 621-8661\r\rWebsite\rhttp://www.gliddenprofessional.com/home\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'PPG Paints',
      Site_Address: '320 Queen St',
      City: 'Southington',
      Zip: '06489',
      Phone_Line: '(860) 621-8661',
      URL: 'http://www.gliddenprofessional.com/home',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.873322700000003,
      Latitude: 41.625345299999999,
      Pin_Code: '5 Gal',
      Location_ID: '4098',
      Site_ID_6: 'CT1476',
      County: 'Hartford',
    },
    portalData: {},
    recordId: '6468',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'PPG Paints\r390 East St\rNew Haven, CT, 06511\r(203) 562-5173\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'PPG Paints',
      Site_Address: '390 East St',
      City: 'New Haven',
      Zip: '06511',
      Phone_Line: '(203) 562-5173',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.910152999999994,
      Latitude: 41.309457100000003,
      Pin_Code: '5 Gal',
      Location_ID: '4106',
      Site_ID_6: 'CT1684',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6471',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'PPG Paints\r211 White St\rDanbury, CT, 06810\r(203) 744-4977\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'PPG Paints',
      Site_Address: '211 White St',
      City: 'Danbury',
      Zip: '06810',
      Phone_Line: '(203) 744-4977',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.441586000000001,
      Latitude: 41.399700600000003,
      Pin_Code: '5 Gal',
      Location_ID: '3869',
      Site_ID_6: 'CT1174',
      County: 'Fairfield',
    },
    portalData: {},
    recordId: '6473',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Preston Transfer Station ( Preston Residents Only)\r108 Ross Rd\rPreston, CT, 06385\r(860) 886-1836\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Preston Transfer Station ( Preston Residents Only)',
      Site_Address: '108 Ross Rd',
      City: 'Preston',
      Zip: '06385',
      Phone_Line: '(860) 886-1836',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -71.996019000000004,
      Latitude: 41.502752000000001,
      Pin_Code: '5 Gal',
      Location_ID: '6213',
      Site_ID_6: 'CT2011',
      County: 'New London',
    },
    portalData: {},
    recordId: '6474',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'R W Hine Ace Hardware\r231 Maple Ave\rCheshire, CT, 06410\r(203) 272-4463\r\rWebsite\rhttp://www.rwhinehardware.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'R W Hine Ace Hardware',
      Site_Address: '231 Maple Ave',
      City: 'Cheshire',
      Zip: '06410',
      Phone_Line: '(203) 272-4463',
      URL: 'http://www.rwhinehardware.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.9041031,
      Latitude: 41.5052144,
      Pin_Code: '5 Gal',
      Location_ID: '4013',
      Site_ID_6: 'CT1145',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6475',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'ReCONNstruction Ctr\r65 Burritt St\rNew Britain, CT, 06053\r(860) 597-3390\r\rWebsite\rhttp://www.reconnstructioncenter.org/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'ReCONNstruction Ctr',
      Site_Address: '65 Burritt St',
      City: 'New Britain',
      Zip: '06053',
      Phone_Line: '(860) 597-3390',
      URL: 'http://www.reconnstructioncenter.org/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.79793,
      Latitude: 41.666863,
      Pin_Code: '5 Gal',
      Location_ID: '3797',
      Site_ID_6: 'CT2203',
      County: 'Hartford',
    },
    portalData: {},
    recordId: '6476',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Regional Water Authority\r90 Sargant Dr\rNew Haven, CT, 06511\r(203) 401-2712\r\rWebsite\rhttp://www.rwater.com/products-services/hazwaste-central\r\rWho Can Use This Program \rFor Households and Some Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the site for details and restrictions. Please do not bring empty, leaking or unlabeled containers. SOME BUSINESSES may use this program by appointment or on special days. Contact the program for details if your paint (or other hazardous waste) is from a business.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Regional Water Authority',
      Site_Address: '90 Sargant Dr',
      City: 'New Haven',
      Zip: '06511',
      Phone_Line: '(203) 401-2712',
      URL: 'http://www.rwater.com/products-services/hazwaste-central',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the site for details and restrictions. Please do not bring empty, leaking or unlabeled containers. SOME BUSINESSES may use this program by appointment or on special days. Contact the program for details if your paint (or other hazardous waste) is from a business.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.925333199999997,
      Latitude: 41.289085300000004,
      Pin_Code: 'HHW',
      Location_ID: '6407',
      Site_ID_6: 'CT1685',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6478',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Regional Water Authority Satellite Event\rVeterans Park\r909 Reef Rd\rFairfield, CT, 06824\r(203) 401-2712\r\rWebsite\rhttp://www.ct.gov/deep/cwp/view.asp?a=2718&q=325448\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Regional Water Authority Satellite Event',
      Site_Address: '909 Reef Rd',
      City: 'Fairfield',
      Zip: '06824',
      Phone_Line: '(203) 401-2712',
      URL: 'http://www.ct.gov/deep/cwp/view.asp?a=2718&q=325448',
      State: 'CT',
      Name2: 'Veterans Park',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.247201000000004,
      Latitude: 41.131490900000003,
      Pin_Code: 'HHW',
      Location_ID: '6408',
      Site_ID_6: 'CT1699',
      County: 'Fairfield',
    },
    portalData: {},
    recordId: '6479',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Regional Water Authority Satellite Event\rMilford DPW Garage\r83 Ford St\rMilford, CT, 06461\r(203) 401-2712\r\rWebsite\rhttp://www.ct.gov/deep/cwp/view.asp?a=2718&q=325448\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Regional Water Authority Satellite Event',
      Site_Address: '83 Ford St',
      City: 'Milford',
      Zip: '06461',
      Phone_Line: '(203) 401-2712',
      URL: 'http://www.ct.gov/deep/cwp/view.asp?a=2718&q=325448',
      State: 'CT',
      Name2: 'Milford DPW Garage',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.071521000000004,
      Latitude: 41.226140000000001,
      Pin_Code: 'HHW',
      Location_ID: '6409',
      Site_ID_6: 'CT1700',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6480',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Regional Water Authority Satellite Event\rWoodbridge Transfer Station\r89 Acorn Hill Rd\rWoodbridge, CT, 06525\r(203) 401-2712\r\rWebsite\rhttp://www.ct.gov/deep/cwp/view.asp?a=2718&q=325448\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Regional Water Authority Satellite Event',
      Site_Address: '89 Acorn Hill Rd',
      City: 'Woodbridge',
      Zip: '06525',
      Phone_Line: '(203) 401-2712',
      URL: 'http://www.ct.gov/deep/cwp/view.asp?a=2718&q=325448',
      State: 'CT',
      Name2: 'Woodbridge Transfer Station',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.048266900000002,
      Latitude: 41.381748899999998,
      Pin_Code: 'HHW',
      Location_ID: '6410',
      Site_ID_6: 'CT1702',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6481',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Ridgefield Transfer Station (Ridgefield Residents Only)\r55 South St\rRidgefield, CT, 0\r(203) 431-2700\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Ridgefield Transfer Station (Ridgefield Residents Only)',
      Site_Address: '55 South St',
      City: 'Ridgefield',
      Zip: '0',
      Phone_Line: '(203) 431-2700',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.492017000000004,
      Latitude: 41.290011,
      Pin_Code: '5 Gal',
      Location_ID: '5240',
      Site_ID_6: 'CT1654',
      County: 'Fairfield',
    },
    portalData: {},
    recordId: '6484',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Rings End\r149 Westport Ave\rNorwalk, CT, 06851\r(203) 663-4450\r\rWebsite\rhttp://www.ringsend.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Rings End',
      Site_Address: '149 Westport Ave',
      City: 'Norwalk',
      Zip: '06851',
      Phone_Line: '(203) 663-4450',
      URL: 'http://www.ringsend.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.400781499999994,
      Latitude: 41.123960699999998,
      Pin_Code: '5 Gal',
      Location_ID: '4178',
      Site_ID_6: 'CT1391',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6485',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Rings End\r28 Hope St\rNiantic, CT, 06357\r(860) 739-5441\r\rWebsite\rhttp://www.ringsend.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Rings End',
      Site_Address: '28 Hope St',
      City: 'Niantic',
      Zip: '06357',
      Phone_Line: '(860) 739-5441',
      URL: 'http://www.ringsend.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.195302600000005,
      Latitude: 41.325408799999998,
      Pin_Code: '5 Gal',
      Location_ID: '4366',
      Site_ID_6: 'CT1373',
      County: 'New London',
    },
    portalData: {},
    recordId: '6486',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Rings End\r129 Danbury Rd\rWilton, CT, 06897\r(203) 761-0688\r\rWebsite\rhttp://www.ringsend.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Rings End',
      Site_Address: '129 Danbury Rd',
      City: 'Wilton',
      Zip: '06897',
      Phone_Line: '(203) 761-0688',
      URL: 'http://www.ringsend.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.4168564,
      Latitude: 41.177720700000002,
      Pin_Code: '5 Gal',
      Location_ID: '4321',
      Site_ID_6: 'CT1586',
      County: 'Fairfield',
    },
    portalData: {},
    recordId: '6487',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Rings End\r85 Bradley Rd\rMadison, CT, 06443\r(203) 245-0414\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Rings End',
      Site_Address: '85 Bradley Rd',
      City: 'Madison',
      Zip: '06443',
      Phone_Line: '(203) 245-0414',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.597841599999995,
      Latitude: 41.282900400000003,
      Pin_Code: '5 Gal',
      Location_ID: '6509',
      Site_ID_6: 'CT2083',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6488',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Rings End\r669 Silas Deane Hwy\rWethersfield, CT, 06109\r(860) 529-3335\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Rings End',
      Site_Address: '669 Silas Deane Hwy',
      City: 'Wethersfield',
      Zip: '06109',
      Phone_Line: '(860) 529-3335',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.662259,
      Latitude: 41.706321,
      Pin_Code: '5 Gal',
      Location_ID: '6439',
      Site_ID_6: 'CT2038',
      County: 'Hartford',
    },
    portalData: {},
    recordId: '6489',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Rings End\r160 Avon St\rStratford, CT, 06615\r(203) 814-3050\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Rings End',
      Site_Address: '160 Avon St',
      City: 'Stratford',
      Zip: '06615',
      Phone_Line: '(203) 814-3050',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.146139399999996,
      Latitude: 41.181366799999999,
      Pin_Code: '5 Gal',
      Location_ID: '6448',
      Site_ID_6: 'CT2030',
      County: 'Fairfield',
    },
    portalData: {},
    recordId: '6490',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Rings End\r921 White Plains Rd\rTrumbull, CT, 06611\r(203) 764-2184\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Rings End',
      Site_Address: '921 White Plains Rd',
      City: 'Trumbull',
      Zip: '06611',
      Phone_Line: '(203) 764-2184',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.196840800000004,
      Latitude: 41.244868400000001,
      Pin_Code: '5 Gal',
      Location_ID: '6962',
      Site_ID_6: 'CT1973',
      County: 'Fairfield',
    },
    portalData: {},
    recordId: '6492',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Rings End Lumber\r25 E Industrial Rd\rBranford, CT, 06405\r(203) 488-3551\r\rWebsite\rhttp://www.ringsend.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Rings End Lumber',
      Site_Address: '25 E Industrial Rd',
      City: 'Branford',
      Zip: '06405',
      Phone_Line: '(203) 488-3551',
      URL: 'http://www.ringsend.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.753989099999998,
      Latitude: 41.297530299999998,
      Pin_Code: '5 Gal',
      Location_ID: '4367',
      Site_ID_6: 'CT1110',
      County: 'Litchfield',
    },
    portalData: {},
    recordId: '6494',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Rings End Lumber\r9 Taylor Ave\rBethel, CT, 06801\r(203) 797-9945\r\rWebsite\rhttp://www.ringsend.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Rings End Lumber',
      Site_Address: '9 Taylor Ave',
      City: 'Bethel',
      Zip: '06801',
      Phone_Line: '(203) 797-9945',
      URL: 'http://www.ringsend.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.416241400000004,
      Latitude: 41.368354400000001,
      Pin_Code: '5 Gal',
      Location_ID: '4318',
      Site_ID_6: 'CT1097',
      County: 'Fairfield',
    },
    portalData: {},
    recordId: '6495',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'RiverCog -Clinton Satellite Collection Event\r117 Nod Rd\rClinton, CT, 06413\r(860) 581-8554\r\rWebsite\rhttp://www.rivercog.org/hhw.html\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'RiverCog -Clinton Satellite Collection Event',
      Site_Address: '117 Nod Rd',
      City: 'Clinton',
      Zip: '06413',
      Phone_Line: '(860) 581-8554',
      URL: 'http://www.rivercog.org/hhw.html',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.554633300000006,
      Latitude: 41.288805199999999,
      Pin_Code: 'HHW',
      Location_ID: '5166',
      Site_ID_6: 'CT1803',
      County: 'Middlesex',
    },
    portalData: {},
    recordId: '6496',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'RiverCog -East Hampton Satellite Collection Event\r20 Gildersleeve Dr\rEast Hampton, CT, 06424\r(860) 581-8554\r\rWebsite\rhttp://www.rivercog.org/hhw.html\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'RiverCog -East Hampton Satellite Collection Event',
      Site_Address: '20 Gildersleeve Dr',
      City: 'East Hampton',
      Zip: '06424',
      Phone_Line: '(860) 581-8554',
      URL: 'http://www.rivercog.org/hhw.html',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.545244600000004,
      Latitude: 41.564335300000003,
      Pin_Code: 'HHW',
      Location_ID: '5165',
      Site_ID_6: 'CT1804',
      County: 'Middlesex',
    },
    portalData: {},
    recordId: '6497',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'RiverCog -East Hampton Satellite Collection Event\rCromwell-Woodside Elementary School\r30 Woodside Dr\rCromwell, CT, 06416\r(860) 581-8554\r\rWebsite\rhttp://www.rivercog.org/hhw.html\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'RiverCog -East Hampton Satellite Collection Event',
      Site_Address: '30 Woodside Dr',
      City: 'Cromwell',
      Zip: '06416',
      Phone_Line: '(860) 581-8554',
      URL: 'http://www.rivercog.org/hhw.html',
      State: 'CT',
      Name2: 'Cromwell-Woodside Elementary School',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.658691,
      Latitude: 41.624514,
      Pin_Code: 'HHW',
      Location_ID: '6905',
      Site_ID_6: 'CT2158',
      County: 'Middlesex',
    },
    portalData: {},
    recordId: '6498',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Rocky Hill Transfer Station ( Rocky Hill Residents Only)\r2 Old Forge Rd\rRocky Hill, CT, 06067\r(860) 258-7709\r\rWebsite\rhttp://www.rockyhillct.gov/DeptPages/recycle.htm\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Rocky Hill Transfer Station ( Rocky Hill Residents Only)',
      Site_Address: '2 Old Forge Rd',
      City: 'Rocky Hill',
      Zip: '06067',
      Phone_Line: '(860) 258-7709',
      URL: 'http://www.rockyhillct.gov/DeptPages/recycle.htm',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.634579000000002,
      Latitude: 41.647195000000004,
      Pin_Code: '5 Gal',
      Location_ID: '4040',
      Site_ID_6: 'CT1655',
      County: 'Hartford',
    },
    portalData: {},
    recordId: '6499',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Schmidts and Serafines True Value\r464 Chase Ave\rWaterbury, CT, 06704\r(203) 754-5186\r\rWebsite\rhttp://schmidtsandserafines.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Schmidts and Serafines True Value',
      Site_Address: '464 Chase Ave',
      City: 'Waterbury',
      Zip: '06704',
      Phone_Line: '(203) 754-5186',
      URL: 'http://schmidtsandserafines.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.038384399999998,
      Latitude: 41.583677299999998,
      Pin_Code: '5 Gal',
      Location_ID: '3835',
      Site_ID_6: 'CT1072',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6501',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'SCRRRA -Norwich Household Hazardous Waste Event\rPublic Works Garage\r50 Clinton Ave\rNorwich, CT, 06360\r(860) 448-4083\r\rWebsite\rhttp://scrrra.org/how-do-i/recycle-hazardous-waste/\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'SCRRRA -Norwich Household Hazardous Waste Event',
      Site_Address: '50 Clinton Ave',
      City: 'Norwich',
      Zip: '06360',
      Phone_Line: '(860) 448-4083',
      URL: 'http://scrrra.org/how-do-i/recycle-hazardous-waste/',
      State: 'CT',
      Name2: 'Public Works Garage',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.109425200000004,
      Latitude: 41.553664099999999,
      Pin_Code: 'HHW',
      Location_ID: '5851',
      Site_ID_6: 'CT1792',
      County: 'New London',
    },
    portalData: {},
    recordId: '6502',
    modId: '72',
  },
  {
    fieldData: {
      Preview_Calc: 'SCRRRA Household Hazardous Waste Collection Event\rStonington Highway Department\r86 Alpha Ave\rStonington, CT, 06378\r(860) 535-5099\r\rWebsite\rhttp://scrrra.org/how-do-i/recycle-hazardous-waste/\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'SCRRRA Household Hazardous Waste Collection Event',
      Site_Address: '86 Alpha Ave',
      City: 'Stonington',
      Zip: '06378',
      Phone_Line: '(860) 535-5099',
      URL: 'http://scrrra.org/how-do-i/recycle-hazardous-waste/',
      State: 'CT',
      Name2: 'Stonington Highway Department',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -71.9023301,
      Latitude: 41.341134799999999,
      Pin_Code: 'HHW',
      Location_ID: '4341',
      Site_ID_6: 'CT1789',
      County: 'New London',
    },
    portalData: {},
    recordId: '6506',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'SCRRRA Household Hazardous Waste Collection Event\r7 Hurlbutt Rd\rGales Ferry, CT, 06335\r(860) 464-8740\r\rWebsite\rhttp://scrrra.org/how-do-i/recycle-hazardous-waste/\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'SCRRRA Household Hazardous Waste Collection Event',
      Site_Address: '7 Hurlbutt Rd',
      City: 'Gales Ferry',
      Zip: '06335',
      Phone_Line: '(860) 464-8740',
      URL: 'http://scrrra.org/how-do-i/recycle-hazardous-waste/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.083830000000006,
      Latitude: 41.428064999999997,
      Pin_Code: 'HHW',
      Location_ID: '4346',
      Site_ID_6: 'CT2025',
      County: 'New London',
    },
    portalData: {},
    recordId: '6509',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'SCRRRA Household Hazardous Waste Collection Event\r298 Norwich Westerly Rd\rNorth Stonington, CT, 06359\r(860) 381-5558\r\rWebsite\rhttp://scrrra.org/how-do-i/recycle-hazardous-waste/\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'SCRRRA Household Hazardous Waste Collection Event',
      Site_Address: '298 Norwich Westerly Rd',
      City: 'North Stonington',
      Zip: '06359',
      Phone_Line: '(860) 381-5558',
      URL: 'http://scrrra.org/how-do-i/recycle-hazardous-waste/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -71.881716,
      Latitude: 41.438685,
      Pin_Code: 'HHW',
      Location_ID: '4488',
      Site_ID_6: 'CT2119',
      County: 'New London',
    },
    portalData: {},
    recordId: '6510',
    modId: '67',
  },
  {
    fieldData: {
      Preview_Calc: 'SCRRRA Household Hazardous Waste Collection Event\rWaterford Public Works\r1000 Hartford Rd\rWaterford, CT, 06385\r(860) 848-9411\r\rWebsite\rhttp://scrrra.org/how-do-i/recycle-hazardous-waste/\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'SCRRRA Household Hazardous Waste Collection Event',
      Site_Address: '1000 Hartford Rd',
      City: 'Waterford',
      Zip: '06385',
      Phone_Line: '(860) 848-9411',
      URL: 'http://scrrra.org/how-do-i/recycle-hazardous-waste/',
      State: 'CT',
      Name2: 'Waterford Public Works',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.163667,
      Latitude: 41.391366,
      Pin_Code: 'HHW',
      Location_ID: '6562',
      Site_ID_6: 'CT1941',
      County: 'New London',
    },
    portalData: {},
    recordId: '6511',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Shagbark True Value Hardware\r21 Mt Parnassus Rd\rEast Haddam, CT, 06423\r(860) 873-1946\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Shagbark True Value Hardware',
      Site_Address: '21 Mt Parnassus Rd',
      City: 'East Haddam',
      Zip: '06423',
      Phone_Line: '(860) 873-1946',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.437532000000004,
      Latitude: 41.466704,
      Pin_Code: '5 Gal',
      Location_ID: '4692',
      Site_ID_6: 'CT1194',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6512',
    modId: '72',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Wiliams\r2945 Main St\rGlastonbury, CT, 06033\r(860) 633-7166\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Wiliams',
      Site_Address: '2945 Main St',
      City: 'Glastonbury',
      Zip: '06033',
      Phone_Line: '(860) 633-7166',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.615597,
      Latitude: 41.723893,
      Pin_Code: '5 Gal',
      Location_ID: '7078',
      Site_ID_6: 'CT2189',
      County: 'Hartford',
    },
    portalData: {},
    recordId: '6513',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Wiliams\r2945 Main St\rGlastonbury, CT, 06033\r(860) 633-7166\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Wiliams',
      Site_Address: '2945 Main St',
      City: 'Glastonbury',
      Zip: '06033',
      Phone_Line: '(860) 633-7166',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.615597,
      Latitude: 41.723893,
      Pin_Code: '5 Gal',
      Location_ID: '7079',
      Site_ID_6: 'CT2189',
      County: 'Hartford',
    },
    portalData: {},
    recordId: '6514',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Wiliams\r2 Montowese Ave\rNorth Haven, CT, 06473\r(203) 745-5238\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Wiliams',
      Site_Address: '2 Montowese Ave',
      City: 'North Haven',
      Zip: '06473',
      Phone_Line: '(203) 745-5238',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.866204,
      Latitude: 41.350527,
      Pin_Code: '5 Gal',
      Location_ID: '4097',
      Site_ID_6: 'CT2037',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6515',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r2 Federal Rd\rBrookfield, CT, 06804\r(203) 775-5936\r\rWebsite\rhttp://www.sherwin-williams.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '2 Federal Rd',
      City: 'Brookfield',
      Zip: '06804',
      Phone_Line: '(203) 775-5936',
      URL: 'http://www.sherwin-williams.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.415877100000003,
      Latitude: 41.430792500000003,
      Pin_Code: '5 Gal',
      Location_ID: '3844',
      Site_ID_6: 'CT1014',
      County: 'Fairfield',
    },
    portalData: {},
    recordId: '6516',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r727 Rubber Ave\rNaugatuck, CT, 06770\r(203) 723-6637\r\rWebsite\rhttp://www.sherwin-williams.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '727 Rubber Ave',
      City: 'Naugatuck',
      Zip: '06770',
      Phone_Line: '(203) 723-6637',
      URL: 'http://www.sherwin-williams.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.077867999999995,
      Latitude: 41.493873000000001,
      Pin_Code: '5 Gal',
      Location_ID: '3839',
      Site_ID_6: 'CT1042',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6519',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r99 Boston Post Rd\rWaterford, CT, 06385\r(860) 443-8516\r\rWebsite\rhttp://www.sherwin-williams.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '99 Boston Post Rd',
      City: 'Waterford',
      Zip: '06385',
      Phone_Line: '(860) 443-8516',
      URL: 'http://www.sherwin-williams.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.122065000000006,
      Latitude: 41.342418700000003,
      Pin_Code: '5 Gal',
      Location_ID: '3813',
      Site_ID_6: 'CT1074',
      County: 'New London',
    },
    portalData: {},
    recordId: '6520',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r750 Long Hill Rd\rGroton, CT, 06340\r(860) 445-1011\r\rWebsite\rhttp://www.sherwin-williams.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '750 Long Hill Rd',
      City: 'Groton',
      Zip: '06340',
      Phone_Line: '(860) 445-1011',
      URL: 'http://www.sherwin-williams.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.053303600000007,
      Latitude: 41.348779399999998,
      Pin_Code: '5 Gal',
      Location_ID: '3784',
      Site_ID_6: 'CT1027',
      County: 'New London',
    },
    portalData: {},
    recordId: '6521',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r46 Glenwood Ave\rBridgeport, CT, 06610\r(203) 384-8862\r\rWebsite\rhttp://www.sherwin-williams.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '46 Glenwood Ave',
      City: 'Bridgeport',
      Zip: '06610',
      Phone_Line: '(203) 384-8862',
      URL: 'http://www.sherwin-williams.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.187850499999996,
      Latitude: 41.203454299999997,
      Pin_Code: '5 Gal',
      Location_ID: '3834',
      Site_ID_6: 'CT1010',
      County: 'Fairfield',
    },
    portalData: {},
    recordId: '6522',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r425 Benton St\rStratford, CT, 06615\r(203) 377-1711\r\rWebsite\rhttp://www.sherwin-williams.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '425 Benton St',
      City: 'Stratford',
      Zip: '06615',
      Phone_Line: '(203) 377-1711',
      URL: 'http://www.sherwin-williams.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.140327799999994,
      Latitude: 41.172698500000003,
      Pin_Code: '5 Gal',
      Location_ID: '3865',
      Site_ID_6: 'CT1069',
      County: 'Fairfield',
    },
    portalData: {},
    recordId: '6523',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r1100 Hope St\rStamford, CT, 06907\r(203) 329-8100\r\rWebsite\rhttp://www.sherwin-williams.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '1100 Hope St',
      City: 'Stamford',
      Zip: '06907',
      Phone_Line: '(203) 329-8100',
      URL: 'http://www.sherwin-williams.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.519930799999997,
      Latitude: 41.0958015,
      Pin_Code: '5 Gal',
      Location_ID: '3866',
      Site_ID_6: 'CT1065',
      County: 'Fairfield',
    },
    portalData: {},
    recordId: '6524',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r1320 Main St\rSte 15\rWillimantic, CT, 06226\r(860) 423-4571\r\rWebsite\rhttp://www.sherwin-williams.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '1320 Main St',
      City: 'Willimantic',
      Zip: '06226',
      Phone_Line: '(860) 423-4571',
      URL: 'http://www.sherwin-williams.com/',
      State: 'CT',
      Name2: '',
      Address2: 'Ste 15',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.229891600000002,
      Latitude: 41.714418199999997,
      Pin_Code: '5 Gal',
      Location_ID: '3867',
      Site_ID_6: 'CT1080',
      County: 'Windham',
    },
    portalData: {},
    recordId: '6525',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r997 Wolcott St\rWaterbury, CT, 06705\r(203) 574-4159\r\rWebsite\rhttp://www.sherwin-williams.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '997 Wolcott St',
      City: 'Waterbury',
      Zip: '06705',
      Phone_Line: '(203) 574-4159',
      URL: 'http://www.sherwin-williams.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.007237000000003,
      Latitude: 41.568389099999997,
      Pin_Code: '5 Gal',
      Location_ID: '3836',
      Site_ID_6: 'CT1073',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6526',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r927 N Colony Rd\rWallingford, CT, 06492\r(203) 269-0255\r\rWebsite\rhttp://www.sherwin-williams.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '927 N Colony Rd',
      City: 'Wallingford',
      Zip: '06492',
      Phone_Line: '(203) 269-0255',
      URL: 'http://www.sherwin-williams.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.810112500000002,
      Latitude: 41.484824099999997,
      Pin_Code: '5 Gal',
      Location_ID: '3831',
      Site_ID_6: 'CT1071',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6527',
    modId: '75',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r411 W Main St\rNorwich, CT, 06360\r(860) 886-0591\r\rWebsite\rhttp://www.sherwin-williams.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '411 W Main St',
      City: 'Norwich',
      Zip: '06360',
      Phone_Line: '(860) 886-0591',
      URL: 'http://www.sherwin-williams.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.094063300000002,
      Latitude: 41.520785400000001,
      Pin_Code: '5 Gal',
      Location_ID: '3812',
      Site_ID_6: 'CT1055',
      County: 'New London',
    },
    portalData: {},
    recordId: '6528',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r239 Kennedy Dr\rPutnam, CT, 06260\r(860) 928-0429\r\rWebsite\rhttp://www.sherwin-williams.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '239 Kennedy Dr',
      City: 'Putnam',
      Zip: '06260',
      Phone_Line: '(860) 928-0429',
      URL: 'http://www.sherwin-williams.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -71.907848999999999,
      Latitude: 41.918388100000001,
      Pin_Code: '5 Gal',
      Location_ID: '3876',
      Site_ID_6: 'CT1059',
      County: 'Windham',
    },
    portalData: {},
    recordId: '6529',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r353 W Main St\rAvon, CT, 06001\r(860) 674-1478\r\rWebsite\rhttp://www.sherwin-williams.com\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '353 W Main St',
      City: 'Avon',
      Zip: '06001',
      Phone_Line: '(860) 674-1478',
      URL: 'http://www.sherwin-williams.com',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.862065599999994,
      Latitude: 41.814568600000001,
      Pin_Code: '5 Gal',
      Location_ID: '3873',
      Site_ID_6: 'CT1001',
      County: 'Hartford',
    },
    portalData: {},
    recordId: '6530',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r255 S Main St\rNewtown, CT, 06470\r(203) 364-9882\r\rWebsite\rhttp://www.sherwin-williams.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '255 S Main St',
      City: 'Newtown',
      Zip: '06470',
      Phone_Line: '(203) 364-9882',
      URL: 'http://www.sherwin-williams.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.271063400000003,
      Latitude: 41.370908100000001,
      Pin_Code: '5 Gal',
      Location_ID: '4939',
      Site_ID_6: 'CT1878',
      County: 'Fairfield',
    },
    portalData: {},
    recordId: '6531',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r304 Main Ave\rNorwalk, CT, 06851\r(203) 845-8850\r\rWebsite\rhttp://www.sherwin-williams.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '304 Main Ave',
      City: 'Norwalk',
      Zip: '06851',
      Phone_Line: '(203) 845-8850',
      URL: 'http://www.sherwin-williams.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.424142000000003,
      Latitude: 41.135711100000002,
      Pin_Code: '5 Gal',
      Location_ID: '3881',
      Site_ID_6: 'CT1052',
      County: 'Fairfield',
    },
    portalData: {},
    recordId: '6532',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r275 Park Ave\rEast Hartford, CT, 06108\r(860) 289-0814\r\rWebsite\rhttp://www.sherwin-williams.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '275 Park Ave',
      City: 'East Hartford',
      Zip: '06108',
      Phone_Line: '(860) 289-0814',
      URL: 'http://www.sherwin-williams.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.623058,
      Latitude: 41.780312299999999,
      Pin_Code: '5 Gal',
      Location_ID: '3803',
      Site_ID_6: 'CT1022',
      County: 'Hartford',
    },
    portalData: {},
    recordId: '6534',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r725 Silas Deane Hwy\rWethersfield, CT, 06109\r(860) 529-2078\r\rWebsite\rhttp://www.sherwin-williams.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '725 Silas Deane Hwy',
      City: 'Wethersfield',
      Zip: '06109',
      Phone_Line: '(860) 529-2078',
      URL: 'http://www.sherwin-williams.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.661773800000006,
      Latitude: 41.7047977,
      Pin_Code: '5 Gal',
      Location_ID: '3804',
      Site_ID_6: 'CT1078',
      County: 'Hartford',
    },
    portalData: {},
    recordId: '6535',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r1077 New Britain Ave\rWest Hartford, CT, 06110\r(860) 570-1216\r\rWebsite\rhttp://www.sherwin-williams.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '1077 New Britain Ave',
      City: 'West Hartford',
      Zip: '06110',
      Phone_Line: '(860) 570-1216',
      URL: 'http://www.sherwin-williams.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.728527700000001,
      Latitude: 41.730976400000003,
      Pin_Code: '5 Gal',
      Location_ID: '3806',
      Site_ID_6: 'CT1076',
      County: 'Hartford',
    },
    portalData: {},
    recordId: '6536',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r1197 Farmington Ave\rBristol, CT, 06010\r(860) 589-8804\r\rWebsite\rhttp://www.sherwin-williams.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '1197 Farmington Ave',
      City: 'Bristol',
      Zip: '06010',
      Phone_Line: '(860) 589-8804',
      URL: 'http://www.sherwin-williams.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.904079999999993,
      Latitude: 41.697858799999999,
      Pin_Code: '5 Gal',
      Location_ID: '3788',
      Site_ID_6: 'CT1012',
      County: 'Farmington',
    },
    portalData: {},
    recordId: '6537',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r257 Broad St\rManchester, CT, 06040\r(860) 643-6636\r\rWebsite\rhttp://www.sherwin-williams.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '257 Broad St',
      City: 'Manchester',
      Zip: '06040',
      Phone_Line: '(860) 643-6636',
      URL: 'http://www.sherwin-williams.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.534307100000007,
      Latitude: 41.780667299999998,
      Pin_Code: '5 Gal',
      Location_ID: '3794',
      Site_ID_6: 'CT1036',
      County: 'Hartford',
    },
    portalData: {},
    recordId: '6538',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r368 Winsted Rd\rTorrington, CT, 06790\r(860) 482-5086\r\rWebsite\rhttp://www.sherwin-williams.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '368 Winsted Rd',
      City: 'Torrington',
      Zip: '06790',
      Phone_Line: '(860) 482-5086',
      URL: 'http://www.sherwin-williams.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.114541799999998,
      Latitude: 41.824512400000003,
      Pin_Code: '5 Gal',
      Location_ID: '3840',
      Site_ID_6: 'CT1070',
      County: 'Litchfield',
    },
    portalData: {},
    recordId: '6539',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r454 Whalley Ave\rNew Haven, CT, 06511\r(203) 782-4930\r\rWebsite\rhttp://www.sherwin-williams.com\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '454 Whalley Ave',
      City: 'New Haven',
      Zip: '06511',
      Phone_Line: '(203) 782-4930',
      URL: 'http://www.sherwin-williams.com',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.948965599999994,
      Latitude: 41.318517800000002,
      Pin_Code: '5 Gal',
      Location_ID: '3868',
      Site_ID_6: 'CT1045',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6540',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r575 Boston Post Rd\rOrange, CT, 06477\r(203) 876-7000\r\rWebsite\rhttp://www.sherwin-williams.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '575 Boston Post Rd',
      City: 'Orange',
      Zip: '06477',
      Phone_Line: '(203) 876-7000',
      URL: 'http://www.sherwin-williams.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.021385199999997,
      Latitude: 41.251398600000002,
      Pin_Code: '5 Gal',
      Location_ID: '3828',
      Site_ID_6: 'CT1058',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6541',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r405 Queen St\rSouthington, CT, 06489\r(860) 621-5396\r\rWebsite\rhttp://www.sherwin-williams.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '405 Queen St',
      City: 'Southington',
      Zip: '06489',
      Phone_Line: '(860) 621-5396',
      URL: 'http://www.sherwin-williams.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.872873900000002,
      Latitude: 41.627952800000003,
      Pin_Code: '5 Gal',
      Location_ID: '3829',
      Site_ID_6: 'CT1063',
      County: 'Hartford',
    },
    portalData: {},
    recordId: '6542',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r2100 Dixwell Ave\rHamden, CT, 06514\r(203) 281-4988\r\rWebsite\rhttp://www.sherwin-williams.com\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '2100 Dixwell Ave',
      City: 'Hamden',
      Zip: '06514',
      Phone_Line: '(203) 281-4988',
      URL: 'http://www.sherwin-williams.com',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.922117299999996,
      Latitude: 41.369791800000002,
      Pin_Code: '5 Gal',
      Location_ID: '3832',
      Site_ID_6: 'CT1029',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6543',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r783 Boston Post Rd\rOld Saybrook, CT, 06475\r(860) 388-3225\r\rWebsite\rhttp://www.sherwin-williams.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '783 Boston Post Rd',
      City: 'Old Saybrook',
      Zip: '06475',
      Phone_Line: '(860) 388-3225',
      URL: 'http://www.sherwin-williams.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.383134200000001,
      Latitude: 41.292845100000001,
      Pin_Code: '5 Gal',
      Location_ID: '3826',
      Site_ID_6: 'CT1056',
      County: 'Middlesex',
    },
    portalData: {},
    recordId: '6544',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r2 Liesl Ln\rBranford, CT, 06405\r(203) 488-1236\r\rWebsite\rhttp://www.sherwin-williams.com\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '2 Liesl Ln',
      City: 'Branford',
      Zip: '06405',
      Phone_Line: '(203) 488-1236',
      URL: 'http://www.sherwin-williams.com',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.821581600000002,
      Latitude: 41.282451100000003,
      Pin_Code: '5 Gal',
      Location_ID: '3815',
      Site_ID_6: 'CT1009',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6546',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r50 Berlin Rd\rCromwell, CT, 06416\r(860) 635-1848\r\rWebsite\rhttp://www.sherwin-williams.com\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '50 Berlin Rd',
      City: 'Cromwell',
      Zip: '06416',
      Phone_Line: '(860) 635-1848',
      URL: 'http://www.sherwin-williams.com',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.687581499999993,
      Latitude: 41.6024551,
      Pin_Code: '5 Gal',
      Location_ID: '3817',
      Site_ID_6: 'CT1018',
      County: 'Middlesex',
    },
    portalData: {},
    recordId: '6547',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r85 Freshwater Blvd\rEnfield, CT, 06082\r(860) 745-2459\r\rWebsite\rhttp://www.sherwin-williams.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '85 Freshwater Blvd',
      City: 'Enfield',
      Zip: '06082',
      Phone_Line: '(860) 745-2459',
      URL: 'http://www.sherwin-williams.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.576176700000005,
      Latitude: 41.991454400000002,
      Pin_Code: '5 Gal',
      Location_ID: '3875',
      Site_ID_6: 'CT1023',
      County: 'Hartford',
    },
    portalData: {},
    recordId: '6548',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r695 Post Rd\rFairfield, CT, 06824\r(203) 255-3523\r\rWebsite\rhttp://www.sherwin-williams.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '695 Post Rd',
      City: 'Fairfield',
      Zip: '06824',
      Phone_Line: '(203) 255-3523',
      URL: 'http://www.sherwin-williams.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.2467939,
      Latitude: 41.147578099999997,
      Pin_Code: '5 Gal',
      Location_ID: '3879',
      Site_ID_6: 'CT1226',
      County: 'Fairfield',
    },
    portalData: {},
    recordId: '6549',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r1062 N Main St\rKillingly, CT, 06241\r(860) 774-9331\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '1062 N Main St',
      City: 'Killingly',
      Zip: '06241',
      Phone_Line: '(860) 774-9331',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -71.876142999999999,
      Latitude: 41.838541800000002,
      Pin_Code: '5 Gal',
      Location_ID: '6000',
      Site_ID_6: 'CT1983',
      County: 'Windham',
    },
    portalData: {},
    recordId: '6550',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r656 New Haven Ave\rDerby, CT, 06418\r(203) 732-4418\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '656 New Haven Ave',
      City: 'Derby',
      Zip: '06418',
      Phone_Line: '(203) 732-4418',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.052068300000002,
      Latitude: 41.313006199999997,
      Pin_Code: '5 Gal',
      Location_ID: '6175',
      Site_ID_6: 'CT1988',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6551',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Simsbury Transfer Station (Simsbury Residents Only)\r74 Wolcott Rd\rSimsbury, CT, 06070\r(860) 658-9481\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Simsbury Transfer Station (Simsbury Residents Only)',
      Site_Address: '74 Wolcott Rd',
      City: 'Simsbury',
      Zip: '06070',
      Phone_Line: '(860) 658-9481',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.781276000000005,
      Latitude: 41.915097000000003,
      Pin_Code: '5 Gal',
      Location_ID: '4730',
      Site_ID_6: 'CT1870',
      County: 'Hartford',
    },
    portalData: {},
    recordId: '6552',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Somers Household Hazardous Facility Events\rSomers Elementary School\r5 Vision Blvd\rSomers, CT, 06071\r(860) 647-5279\r\rWebsite\rhttp://sanitation1.townofmanchester.org/index.cfm/household-hazardous-waste-collection/\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Somers Household Hazardous Facility Events',
      Site_Address: '5 Vision Blvd',
      City: 'Somers',
      Zip: '06071',
      Phone_Line: '(860) 647-5279',
      URL: 'http://sanitation1.townofmanchester.org/index.cfm/household-hazardous-waste-collection/',
      State: 'CT',
      Name2: 'Somers Elementary School',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.47673,
      Latitude: 41.97616,
      Pin_Code: 'HHW',
      Location_ID: '6902',
      Site_ID_6: 'CT2152',
      County: 'Tolland',
    },
    portalData: {},
    recordId: '6553',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Somers Transfer Station\r127 Egypt Rd\rSomers, CT, 06071\r(860) 763-8236\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Somers Transfer Station',
      Site_Address: '127 Egypt Rd',
      City: 'Somers',
      Zip: '06071',
      Phone_Line: '(860) 763-8236',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.459643,
      Latitude: 41.963378,
      Pin_Code: '5 Gal',
      Location_ID: '7164',
      Site_ID_6: 'CT1989',
      County: 'Tolland',
    },
    portalData: {},
    recordId: '6554',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'South Broad Paint Center\r1267 S Broad St\rWallingford, CT, 06492\r(203) 774-4700\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'South Broad Paint Center',
      Site_Address: '1267 S Broad St',
      City: 'Wallingford',
      Zip: '06492',
      Phone_Line: '(203) 774-4700',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.810233100000005,
      Latitude: 41.506754700000002,
      Pin_Code: '5 Gal',
      Location_ID: '6335',
      Site_ID_6: 'CT2032',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6555',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Southbury Transfer Station (Southbury Residents Only)\r231 Kettletown Rd\rSouthbury, CT, 06488\r(203) 262-0662\r\rWebsite\rhttp://www.southbury-ct.org/content/992/121/159/167/1377.aspx\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Southbury Transfer Station (Southbury Residents Only)',
      Site_Address: '231 Kettletown Rd',
      City: 'Southbury',
      Zip: '06488',
      Phone_Line: '(203) 262-0662',
      URL: 'http://www.southbury-ct.org/content/992/121/159/167/1377.aspx',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.207048,
      Latitude: 41.472614999999998,
      Pin_Code: '5 Gal',
      Location_ID: '4041',
      Site_ID_6: 'CT1656',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6556',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Stewards Ace Hardware\r114 E Main St\rClinton, CT, 06413\r(860) 669-0505\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Stewards Ace Hardware',
      Site_Address: '114 E Main St',
      City: 'Clinton',
      Zip: '06413',
      Phone_Line: '(860) 669-0505',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.517737699999998,
      Latitude: 41.275908600000001,
      Pin_Code: '5 Gal',
      Location_ID: '3816',
      Site_ID_6: 'CT1017',
      County: 'Middlesex',
    },
    portalData: {},
    recordId: '6557',
    modId: '72',
  },
  {
    fieldData: {
      Preview_Calc: 'Stony Hill Hardware\r34 Stony Hill Rd\rBethel, CT, 06801\r(203) 792-4043\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Stony Hill Hardware',
      Site_Address: '34 Stony Hill Rd',
      City: 'Bethel',
      Zip: '06801',
      Phone_Line: '(203) 792-4043',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.390468600000005,
      Latitude: 41.416734499999997,
      Pin_Code: '5 Gal',
      Location_ID: '3842',
      Site_ID_6: 'CT1003',
      County: 'Fairfield',
    },
    portalData: {},
    recordId: '6559',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Thomaston Household Hazardous Collection Event\rThomaston High School\r185 Branch Rd\rThomaston, CT, 06787\r(203) 757-0535\r\rWebsite\rhttp://www.ct.gov/deep/cwp/view.asp?a=2718&q=325448&deepNav_GID=1646\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Thomaston Household Hazardous Collection Event',
      Site_Address: '185 Branch Rd',
      City: 'Thomaston',
      Zip: '06787',
      Phone_Line: '(203) 757-0535',
      URL: 'http://www.ct.gov/deep/cwp/view.asp?a=2718&q=325448&deepNav_GID=1646',
      State: 'CT',
      Name2: 'Thomaston High School',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.095842599999997,
      Latitude: 41.658324999999998,
      Pin_Code: 'HHW',
      Location_ID: '5163',
      Site_ID_6: 'CT1913',
      County: 'Litchfield',
    },
    portalData: {},
    recordId: '6560',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Thompson Transfer Station\r185 Pasay Rd\rThompson, CT, 06255\r(860) 923-9820\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Thompson Transfer Station',
      Site_Address: '185 Pasay Rd',
      City: 'Thompson',
      Zip: '06255',
      Phone_Line: '(860) 923-9820',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -71.874189,
      Latitude: 41.984836,
      Pin_Code: '5 Gal',
      Location_ID: '7084',
      Site_ID_6: 'CT2183',
      County: 'Windham',
    },
    portalData: {},
    recordId: '6561',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Town of Clinton Transfer Station\r115 Nod Rd\rClinton, CT, 06413\r(860) 664-1100\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Town of Clinton Transfer Station',
      Site_Address: '115 Nod Rd',
      City: 'Clinton',
      Zip: '06413',
      Phone_Line: '(860) 664-1100',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.552516,
      Latitude: 41.28997,
      Pin_Code: '5 Gal',
      Location_ID: '7069',
      Site_ID_6: 'CT2184',
      County: 'Middlesex',
    },
    portalData: {},
    recordId: '6564',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'True Value Hardware\r20 Hartford Ave\rGranby, CT, 06035\r(860) 653-2598\r\rWebsite\rhttp://www.truevalue.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'True Value Hardware',
      Site_Address: '20 Hartford Ave',
      City: 'Granby',
      Zip: '06035',
      Phone_Line: '(860) 653-2598',
      URL: 'http://www.truevalue.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.786374499999994,
      Latitude: 41.951763900000003,
      Pin_Code: '5 Gal',
      Location_ID: '3793',
      Site_ID_6: 'CT1025',
      County: 'Hartford',
    },
    portalData: {},
    recordId: '6574',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'True Value Hardware\r348 Bantam Rd\rLitchfield, CT, 06759\r(860) 567-8588\r\rWebsite\rhttp://www.truevalue.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'True Value Hardware',
      Site_Address: '348 Bantam Rd',
      City: 'Litchfield',
      Zip: '06759',
      Phone_Line: '(860) 567-8588',
      URL: 'http://www.truevalue.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.208095099999994,
      Latitude: 41.745455399999997,
      Pin_Code: '5 Gal',
      Location_ID: '3837',
      Site_ID_6: 'CT1034',
      County: 'Litchfield',
    },
    portalData: {},
    recordId: '6576',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'True Value Hardware\r211 Greenwood Ave\rBethel, CT, 06801\r(203) 748-4200\r\rWebsite\rhttp://www.truevalue.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'True Value Hardware',
      Site_Address: '211 Greenwood Ave',
      City: 'Bethel',
      Zip: '06801',
      Phone_Line: '(203) 748-4200',
      URL: 'http://www.truevalue.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.415907599999997,
      Latitude: 41.372309700000002,
      Pin_Code: '5 Gal',
      Location_ID: '3845',
      Site_ID_6: 'CT1005',
      County: 'Fairfield',
    },
    portalData: {},
    recordId: '6577',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'True Value Hardware\r276 Main St\rWinsted, CT, 06098\r(860) 379-6747\r\rWebsite\rhttp://www.truevalue.com/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'True Value Hardware',
      Site_Address: '276 Main St',
      City: 'Winsted',
      Zip: '06098',
      Phone_Line: '(860) 379-6747',
      URL: 'http://www.truevalue.com/',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.066952499999999,
      Latitude: 41.921552599999998,
      Pin_Code: '5 Gal',
      Location_ID: '3801',
      Site_ID_6: 'CT1083',
      County: 'Litchfield',
    },
    portalData: {},
    recordId: '6578',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'True Value of Bethlehem\r101 Main St S\rBethlehem, CT, 06751\r(203) 266-5262\r\rWebsite\rhttp://ww3.truevalue.com/bethlehem/Home.aspx\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'True Value of Bethlehem',
      Site_Address: '101 Main St S',
      City: 'Bethlehem',
      Zip: '06751',
      Phone_Line: '(203) 266-5262',
      URL: 'http://ww3.truevalue.com/bethlehem/Home.aspx',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.208273000000005,
      Latitude: 41.633561999999998,
      Pin_Code: '5 Gal',
      Location_ID: '4069',
      Site_ID_6: 'CT1006',
      County: 'Litchfield',
    },
    portalData: {},
    recordId: '6579',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Tuxis Lumber Company\r85 Bradley Rd\rMadison, CT, 06443\r(203) 245-0414\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Tuxis Lumber Company',
      Site_Address: '85 Bradley Rd',
      City: 'Madison',
      Zip: '06443',
      Phone_Line: '(203) 245-0414',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.597841599999995,
      Latitude: 41.282900400000003,
      Pin_Code: '5 Gal',
      Location_ID: '3821',
      Site_ID_6: 'CT2083',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6580',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'United Paint and Wallpaper\r669 Silas Deane Hwy\rWethersfield, CT, 06109\r(860) 529-3335\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'United Paint and Wallpaper',
      Site_Address: '669 Silas Deane Hwy',
      City: 'Wethersfield',
      Zip: '06109',
      Phone_Line: '(860) 529-3335',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.662259,
      Latitude: 41.706321,
      Pin_Code: '5 Gal',
      Location_ID: '3805',
      Site_ID_6: 'CT2038',
      County: 'Hartford',
    },
    portalData: {},
    recordId: '6581',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'West Hartford Transfer Station (West Hartford Residents Only)\r25 Brixton St\rWest Hartford, CT, 06110\r(860) 231-8100\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'West Hartford Transfer Station (West Hartford Residents Only)',
      Site_Address: '25 Brixton St',
      City: 'West Hartford',
      Zip: '06110',
      Phone_Line: '(860) 231-8100',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.720676999999995,
      Latitude: 41.733440999999999,
      Pin_Code: '5 Gal',
      Location_ID: '6189',
      Site_ID_6: 'CT2001',
      County: 'Hartford',
    },
    portalData: {},
    recordId: '6584',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Westport Transfer Station (Westport Residents Only)\r300 Sherwood Island Connector\rWestport, CT, 06880\r(203) 341-1120\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Westport Transfer Station (Westport Residents Only)',
      Site_Address: '300 Sherwood Island Connector',
      City: 'Westport',
      Zip: '06880',
      Phone_Line: '(203) 341-1120',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.333076,
      Latitude: 41.128806,
      Pin_Code: '5 Gal',
      Location_ID: '6466',
      Site_ID_6: 'CT2053',
      County: 'Fairfield',
    },
    portalData: {},
    recordId: '6585',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Wilton Hardware\r21 River Rd\rWilton, CT, 06897\r(203) 529-3650\r\rWebsite\rhttp://www.wiltonhardware.com/Home/tabid/179937/mo/1/Default.aspx\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Wilton Hardware',
      Site_Address: '21 River Rd',
      City: 'Wilton',
      Zip: '06897',
      Phone_Line: '(203) 529-3650',
      URL: 'http://www.wiltonhardware.com/Home/tabid/179937/mo/1/Default.aspx',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.430876600000005,
      Latitude: 41.190069700000002,
      Pin_Code: '5 Gal',
      Location_ID: '3857',
      Site_ID_6: 'CT1081',
      County: 'Fairfield',
    },
    portalData: {},
    recordId: '6586',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Windham Transfer Station (Windham residents only)\r8 Industrial Park\rNorth Windham, CT, 06256\r(860) 208-0670\r\rWebsite\rhttp://www.windhamct.com/department.htm?id=56lba5n4&m=boards\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Windham Transfer Station (Windham residents only)',
      Site_Address: '8 Industrial Park',
      City: 'North Windham',
      Zip: '06256',
      Phone_Line: '(860) 208-0670',
      URL: 'http://www.windhamct.com/department.htm?id=56lba5n4&m=boards',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.175712000000004,
      Latitude: 41.742294999999999,
      Pin_Code: '5 Gal',
      Location_ID: '4334',
      Site_ID_6: 'CT1777',
      County: 'Windham',
    },
    portalData: {},
    recordId: '6587',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Windsor Transfer Station (Windsor & Bloomfield Residents only)\r500 Huckleberry Rd\rWindsor, CT, 06095\r(860) 285-1833\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Windsor Transfer Station (Windsor & Bloomfield Residents only)',
      Site_Address: '500 Huckleberry Rd',
      City: 'Windsor',
      Zip: '06095',
      Phone_Line: '(860) 285-1833',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.708779000000007,
      Latitude: 41.891309999999997,
      Pin_Code: '5 Gal',
      Location_ID: '5894',
      Site_ID_6: 'CT1980',
      County: 'Hartford',
    },
    portalData: {},
    recordId: '6589',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Wolcott Transfer Station (Wolcott Residents Only)\r48 Todd Rd\rWolcott, CT, 06716\r(203) 879-8142 or (203) 879-8100 Ext 103\r\rWebsite\rhttp://www.wolcottct.org/pages/page_content/secondary_services_recycling_recycling-center_our-recycling-center.aspx\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Wolcott Transfer Station (Wolcott Residents Only)',
      Site_Address: '48 Todd Rd',
      City: 'Wolcott',
      Zip: '06716',
      Phone_Line: '(203) 879-8142 or (203) 879-8100 Ext 103',
      URL: 'http://www.wolcottct.org/pages/page_content/secondary_services_recycling_recycling-center_our-recycling-center.aspx',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -72.978826,
      Latitude: 41.576391,
      Pin_Code: 'HHW',
      Location_ID: '4487',
      Site_ID_6: 'CT2153',
      County: '',
    },
    portalData: {},
    recordId: '6590',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Woodbridge Transfer Station (Woodbridge Residents Only)\r85 Acorn Hill Rd Ext\rWoodbridge, CT, 06525\r(203) 393-3090\r\rWebsite\rhttps://www.woodbridgect.org/163/Transfer-Station\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Woodbridge Transfer Station (Woodbridge Residents Only)',
      Site_Address: '85 Acorn Hill Rd Ext',
      City: 'Woodbridge',
      Zip: '06525',
      Phone_Line: '(203) 393-3090',
      URL: 'https://www.woodbridgect.org/163/Transfer-Station',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -73.047993000000005,
      Latitude: 41.381779999999999,
      Pin_Code: '5 Gal',
      Location_ID: '4206',
      Site_ID_6: 'CT1692',
      County: 'New Haven',
    },
    portalData: {},
    recordId: '6591',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Woodstock Transfer Station\r49 Paine District Rd\rWoodstock, CT, 06281\r(860) 928-2770\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 162,
      Active: 'Y',
      Name: 'Woodstock Transfer Station',
      Site_Address: '49 Paine District Rd',
      City: 'Woodstock',
      Zip: '06281',
      Phone_Line: '(860) 928-2770',
      URL: '',
      State: 'CT',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -71.955706,
      Latitude: 41.973864,
      Pin_Code: '5 Gal',
      Location_ID: '7085',
      Site_ID_6: 'CT2094',
      County: 'Windham',
    },
    portalData: {},
    recordId: '6592',
    modId: '68',
  },

  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r1072 Rogue River Hwy\rGrants Pass, OR, 97527\r(541) 474-5985\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '1072 Rogue River Hwy',
      City: 'Grants Pass',
      Zip: '97527',
      Phone_Line: '(541) 474-5985',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.3182298,
      Latitude: 42.4237398,
      Pin_Code: '20 Gal',
      Location_ID: '194',
      Site_ID_6: 'OR1021',
      County: 'Josephine',
    },
    portalData: {},
    recordId: '9530',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r115 SE Emigrant Ave\rPendleton, OR, 97801\r(541) 276-3463\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '115 SE Emigrant Ave',
      City: 'Pendleton',
      Zip: '97801',
      Phone_Line: '(541) 276-3463',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -118.78502640000001,
      Latitude: 45.671262200000001,
      Pin_Code: '5 Gal',
      Location_ID: '203',
      Site_ID_6: 'OR1025',
      County: 'Umatilla',
    },
    portalData: {},
    recordId: '9531',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r1740 E Powell Blvd\rGresham, OR, 97030\r(503) 666-3891\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '1740 E Powell Blvd',
      City: 'Gresham',
      Zip: '97030',
      Phone_Line: '(503) 666-3891',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.4149583,
      Latitude: 45.497526700000002,
      Pin_Code: '5 Gal',
      Location_ID: '4289',
      Site_ID_6: 'OR1123',
      County: 'Multnomah',
    },
    portalData: {},
    recordId: '9532',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r348 SE 10th Ave\rHillsboro, OR, 97123\r(503) 640-2772\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '348 SE 10th Ave',
      City: 'Hillsboro',
      Zip: '97123',
      Phone_Line: '(503) 640-2772',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.9735575,
      Latitude: 45.519166599999998,
      Pin_Code: '5 Gal',
      Location_ID: '4293',
      Site_ID_6: 'OR1124',
      County: 'Washington',
    },
    portalData: {},
    recordId: '9533',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r2508 Portland Rd\rNewberg, OR, 97132\r(503) 537-2078\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '2508 Portland Rd',
      City: 'Newberg',
      Zip: '97132',
      Phone_Line: '(503) 537-2078',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.9552903,
      Latitude: 45.303404999999998,
      Pin_Code: '5 Gal',
      Location_ID: '4296',
      Site_ID_6: 'OR1134',
      County: 'Yamhill',
    },
    portalData: {},
    recordId: '9534',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r1289 SE 1st Ave\rOntario, OR, 97914\r(541) 889-1153\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '1289 SE 1st Ave',
      City: 'Ontario',
      Zip: '97914',
      Phone_Line: '(541) 889-1153',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -116.9456307,
      Latitude: 44.024074300000002,
      Pin_Code: '5 Gal',
      Location_ID: '4369',
      Site_ID_6: 'OR1133',
      County: 'Malheur',
    },
    portalData: {},
    recordId: '9535',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r11475 SE 82nd Ave\rClackamas, OR, 97086\r(503) 659-6712\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '11475 SE 82nd Ave',
      City: 'Clackamas',
      Zip: '97086',
      Phone_Line: '(503) 659-6712',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.57965780000001,
      Latitude: 45.440065799999999,
      Pin_Code: '5 Gal',
      Location_ID: '4648',
      Site_ID_6: 'OR1125',
      County: 'Clackamas',
    },
    portalData: {},
    recordId: '9536',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'A-Boy Supply\r7365 SW Barbur Blvd\rPortland, OR, 97219\r(503) 245-0714\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'A-Boy Supply',
      Site_Address: '7365 SW Barbur Blvd',
      City: 'Portland',
      Zip: '97219',
      Phone_Line: '(503) 245-0714',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.684075,
      Latitude: 45.471723,
      Pin_Code: '5 Gal',
      Location_ID: '7102',
      Site_ID_6: 'OR2069',
      County: 'Multnomah',
    },
    portalData: {},
    recordId: '9537',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Albany Lebanon Sanitation HHW Event\r1214 Montgomery St SE\rAlbany, OR, 97321\r(541) 928-2551\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Albany Lebanon Sanitation HHW Event',
      Site_Address: '1214 Montgomery St SE',
      City: 'Albany',
      Zip: '97321',
      Phone_Line: '(541) 928-2551',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.101449,
      Latitude: 44.628424000000003,
      Pin_Code: 'HHW',
      Location_ID: '5969',
      Site_ID_6: 'OR5004',
      County: 'Linn',
    },
    portalData: {},
    recordId: '9538',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Albany Restore\r1225 SE 6th Ave\rAlbany, OR, 97321\r(541) 924-1450\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Albany Restore',
      Site_Address: '1225 SE 6th Ave',
      City: 'Albany',
      Zip: '97321',
      Phone_Line: '(541) 924-1450',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.08936869999999,
      Latitude: 44.635314700000002,
      Pin_Code: '20 Gal',
      Location_ID: '211',
      Site_ID_6: 'OR1027',
      County: 'Linn',
    },
    portalData: {},
    recordId: '9539',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Benton Habitat for Humanity Restore\r4840 SW Philomath Blvd\rCorvallis, OR, 97330\r(541) 752-6637\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Benton Habitat for Humanity Restore',
      Site_Address: '4840 SW Philomath Blvd',
      City: 'Corvallis',
      Zip: '97330',
      Phone_Line: '(541) 752-6637',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.30591219999999,
      Latitude: 44.5521551,
      Pin_Code: '20 Gal Reuse',
      Location_ID: '215',
      Site_ID_6: 'OR1028',
      County: 'Benton',
    },
    portalData: {},
    recordId: '9543',
    modId: '72',
  },
  {
    fieldData: {
      Preview_Calc: 'Canby N Will Valley Restore\r411 S Redwood St\rCanby, OR, 97013\r(503) 263-6691\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Canby N Will Valley Restore',
      Site_Address: '411 S Redwood St',
      City: 'Canby',
      Zip: '97013',
      Phone_Line: '(503) 263-6691',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.6765417,
      Latitude: 45.261091999999998,
      Pin_Code: 'Over 20 Reuse',
      Location_ID: '4650',
      Site_ID_6: 'OR1117',
      County: 'Clackamas',
    },
    portalData: {},
    recordId: '9544',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Cascade Home Center\r104 S Mill St\rCreswell, OR, 97426\r(541) 895-4166\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Cascade Home Center',
      Site_Address: '104 S Mill St',
      City: 'Creswell',
      Zip: '97426',
      Phone_Line: '(541) 895-4166',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.0171011,
      Latitude: 43.9168333,
      Pin_Code: '20 Gal',
      Location_ID: '3454',
      Site_ID_6: 'OR1105',
      County: 'Lane',
    },
    portalData: {},
    recordId: '9545',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'City Lumber Co\r2142 Commercial St\rAstoria, OR, 97103\r(503) 325-4511\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'City Lumber Co',
      Site_Address: '2142 Commercial St',
      City: 'Astoria',
      Zip: '97103',
      Phone_Line: '(503) 325-4511',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.817965,
      Latitude: 46.189923399999998,
      Pin_Code: '20 Gal',
      Location_ID: '259',
      Site_ID_6: 'OR1077',
      County: 'Clatsop',
    },
    portalData: {},
    recordId: '9547',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Clatskanie Household Hazardous Waste Event\rClatskanie City Park\r300 NE Park St\rClatskanie, OR, 97016\r(503) 397-7259\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Clatskanie Household Hazardous Waste Event',
      Site_Address: '300 NE Park St',
      City: 'Clatskanie',
      Zip: '97016',
      Phone_Line: '(503) 397-7259',
      URL: '',
      State: 'OR',
      Name2: 'Clatskanie City Park',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.1997478,
      Latitude: 46.105407,
      Pin_Code: 'HHW',
      Location_ID: '5990',
      Site_ID_6: 'OR5018',
      County: 'Columbia',
    },
    portalData: {},
    recordId: '9548',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Colorcraft Paint\r2646 NE Diamond Lake Blvd\rRoseburg, OR, 97470\r(541) 672-2252\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Colorcraft Paint',
      Site_Address: '2646 NE Diamond Lake Blvd',
      City: 'Roseburg',
      Zip: '97470',
      Phone_Line: '(541) 672-2252',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.321365,
      Latitude: 43.213422999999999,
      Pin_Code: '20 Gal',
      Location_ID: '5728',
      Site_ID_6: 'OR1114',
      County: 'Douglas',
    },
    portalData: {},
    recordId: '9549',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Columbia Co Habitat for Humanity\r164 Little St\rSaint Helens, OR, 97051\r(503) 366-1400\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Columbia Co Habitat for Humanity',
      Site_Address: '164 Little St',
      City: 'Saint Helens',
      Zip: '97051',
      Phone_Line: '(503) 366-1400',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.824209,
      Latitude: 45.856893900000003,
      Pin_Code: '20 Gal Reuse',
      Location_ID: '4651',
      Site_ID_6: 'OR1147',
      County: 'Columbia',
    },
    portalData: {},
    recordId: '9551',
    modId: '72',
  },
  {
    fieldData: {
      Preview_Calc: 'Columbia Gorge ReStore\r1001 W 6th St\rThe Dalles, OR, 97058\r(541) 296-4486\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Columbia Gorge ReStore',
      Site_Address: '1001 W 6th St',
      City: 'The Dalles',
      Zip: '97058',
      Phone_Line: '(541) 296-4486',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -121.1972063,
      Latitude: 45.604657699999997,
      Pin_Code: '20 Gal Reuse',
      Location_ID: '6046',
      Site_ID_6: 'OR1198',
      County: 'Wasco',
    },
    portalData: {},
    recordId: '9552',
    modId: '73',
  },
  {
    fieldData: {
      Preview_Calc: 'Coos Bay Habitat for Humanity\r776 S 2nd St\rCoos Bay, OR, 97420\r(541) 756-1600\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Coos Bay Habitat for Humanity',
      Site_Address: '776 S 2nd St',
      City: 'Coos Bay',
      Zip: '97420',
      Phone_Line: '(541) 756-1600',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -124.2146704,
      Latitude: 43.361544299999998,
      Pin_Code: '20 Gal Reuse',
      Location_ID: '5802',
      Site_ID_6: 'OR1188',
      County: 'Coos',
    },
    portalData: {},
    recordId: '9553',
    modId: '73',
  },
  {
    fieldData: {
      Preview_Calc: 'Coos County Solid Waste Department\rBeaver Hill Disposal Site\r55722 Hwy 101\rCoos Bay, OR, 97420\r(541) 396-7623\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Coos County Solid Waste Department',
      Site_Address: '55722 Hwy 101',
      City: 'Coos Bay',
      Zip: '97420',
      Phone_Line: '(541) 396-7623',
      URL: '',
      State: 'OR',
      Name2: 'Beaver Hill Disposal Site',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -124.213167,
      Latitude: 43.366445800000001,
      Pin_Code: '20 Gal',
      Location_ID: '242',
      Site_ID_6: 'OR1061',
      County: 'Coos',
    },
    portalData: {},
    recordId: '9554',
    modId: '73',
  },
  {
    fieldData: {
      Preview_Calc: 'Coquille Supply Inc\r10054 Hwy 42\rCoquille, OR, 97423\r(541) 396-4264\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Coquille Supply Inc',
      Site_Address: '10054 Hwy 42',
      City: 'Coquille',
      Zip: '97423',
      Phone_Line: '(541) 396-4264',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -124.1765505,
      Latitude: 43.1493696,
      Pin_Code: 'Over 20',
      Location_ID: '4241',
      Site_ID_6: 'OR1115',
      County: 'Coos',
    },
    portalData: {},
    recordId: '9555',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Cottage Grove Household Hazardous Waste Event\rSouth Lane Fire and Rescue\r233 Harrison St\rCottage Grove, OR, 97424\r(541) 682-4120\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Cottage Grove Household Hazardous Waste Event',
      Site_Address: '233 Harrison St',
      City: 'Cottage Grove',
      Zip: '97424',
      Phone_Line: '(541) 682-4120',
      URL: '',
      State: 'OR',
      Name2: 'South Lane Fire and Rescue',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.066008,
      Latitude: 43.789765299999999,
      Pin_Code: 'HHW',
      Location_ID: '5997',
      Site_ID_6: 'OR5031',
      County: 'Lane',
    },
    portalData: {},
    recordId: '9557',
    modId: '74',
  },
  {
    fieldData: {
      Preview_Calc: 'Crook County Household Hazardous Waste Facility and Landfill\r110 SW Landfill Rd\rPrineville, OR, 97754\r(541) 447-2398\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Crook County Household Hazardous Waste Facility and Landfill',
      Site_Address: '110 SW Landfill Rd',
      City: 'Prineville',
      Zip: '97754',
      Phone_Line: '(541) 447-2398',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -120.912907,
      Latitude: 44.301951,
      Pin_Code: 'HHW',
      Location_ID: '265',
      Site_ID_6: 'OR2738',
      County: 'Crook',
    },
    portalData: {},
    recordId: '9558',
    modId: '72',
  },
  {
    fieldData: {
      Preview_Calc: 'Curry Community Cares\r616 Memory Ln\rBrookings, OR, 97415\r(541) 412-7166\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Curry Community Cares',
      Site_Address: '616 Memory Ln',
      City: 'Brookings',
      Zip: '97415',
      Phone_Line: '(541) 412-7166',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -124.2809244,
      Latitude: 42.049137299999998,
      Pin_Code: '20 Gal Reuse',
      Location_ID: '5243',
      Site_ID_6: 'OR1180',
      County: 'Curry',
    },
    portalData: {},
    recordId: '9559',
    modId: '72',
  },
  {
    fieldData: {
      Preview_Calc: 'Denfeld Paints\r2121 NE Division St\rBend, OR, 97701\r(541) 382-4171\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Denfeld Paints',
      Site_Address: '2121 NE Division St',
      City: 'Bend',
      Zip: '97701',
      Phone_Line: '(541) 382-4171',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -121.3063077,
      Latitude: 44.071092499999999,
      Pin_Code: '5 Gal',
      Location_ID: '4932',
      Site_ID_6: 'OR1154',
      County: 'Deschutes',
    },
    portalData: {},
    recordId: '9560',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Denfeld Paints\r641 NW Fir Ave\rRedmond, OR, 97756\r(541) 548-7707\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Denfeld Paints',
      Site_Address: '641 NW Fir Ave',
      City: 'Redmond',
      Zip: '97756',
      Phone_Line: '(541) 548-7707',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -121.1750931,
      Latitude: 44.281348600000001,
      Pin_Code: '5 Gal',
      Location_ID: '4933',
      Site_ID_6: 'OR1155',
      County: 'Deschutes',
    },
    portalData: {},
    recordId: '9561',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Dicks Color Center\r909 SE Salmon St\rPortland, OR, 97214\r(503) 236-6919\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Dicks Color Center',
      Site_Address: '909 SE Salmon St',
      City: 'Portland',
      Zip: '97214',
      Phone_Line: '(503) 236-6919',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.65645859999999,
      Latitude: 45.514530700000002,
      Pin_Code: '5 Gal',
      Location_ID: '251',
      Site_ID_6: 'OR1066',
      County: 'Multnomah',
    },
    portalData: {},
    recordId: '9562',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Drakes Paint And Supply\r2929 N Pacific Hwy\rMedford, OR, 97501\r(541) 773-3335\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Drakes Paint And Supply',
      Site_Address: '2929 N Pacific Hwy',
      City: 'Medford',
      Zip: '97501',
      Phone_Line: '(541) 773-3335',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.8961323,
      Latitude: 42.3505684,
      Pin_Code: '5 Gal',
      Location_ID: '260',
      Site_ID_6: 'OR1078',
      County: 'Jackson',
    },
    portalData: {},
    recordId: '9563',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'East Lincoln County\rToledo Recycling & Transfer Center (Lincoln County Dahl Sanitary Service)\r5441 W Hwy 20\rToledo, OR, 97391\r(541) 336-2932\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'East Lincoln County',
      Site_Address: '5441 W Hwy 20',
      City: 'Toledo',
      Zip: '97391',
      Phone_Line: '(541) 336-2932',
      URL: '',
      State: 'OR',
      Name2: 'Toledo Recycling & Transfer Center (Lincoln County Dahl Sanitary Service)',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.919524,
      Latitude: 44.6460103,
      Pin_Code: '5 Gal',
      Location_ID: '282',
      Site_ID_6: 'OR1084',
      County: 'Lincoln',
    },
    portalData: {},
    recordId: '9564',
    modId: '73',
  },
  {
    fieldData: {
      Preview_Calc: 'Elgin Solid Waste Transfer Station\r75718 Hemlock St\rElgin, OR, 97827\r(541) 437-2253\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Elgin Solid Waste Transfer Station',
      Site_Address: '75718 Hemlock St',
      City: 'Elgin',
      Zip: '97827',
      Phone_Line: '(541) 437-2253',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -117.92874,
      Latitude: 45.560056,
      Pin_Code: '20 Gal',
      Location_ID: '6465',
      Site_ID_6: 'OR1202',
      County: 'Union',
    },
    portalData: {},
    recordId: '9565',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Estacada True Value Hardware\r310 S Main St\rEstacada, OR, 97023\r(503) 630-3769\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Estacada True Value Hardware',
      Site_Address: '310 S Main St',
      City: 'Estacada',
      Zip: '97023',
      Phone_Line: '(503) 630-3769',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.3331732,
      Latitude: 45.287450700000001,
      Pin_Code: '5 Gal',
      Location_ID: '235',
      Site_ID_6: 'OR1052',
      County: 'Clackamas',
    },
    portalData: {},
    recordId: '9566',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Florence Habitat for Humanity\r2016 Hwy 101\rFlorence, OR, 97439\r(541) 902-9227\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Florence Habitat for Humanity',
      Site_Address: '2016 Hwy 101',
      City: 'Florence',
      Zip: '97439',
      Phone_Line: '(541) 902-9227',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -124.101283,
      Latitude: 43.984263200000001,
      Pin_Code: '20 Gal Reuse',
      Location_ID: '4614',
      Site_ID_6: 'OR1148',
      County: 'Lane',
    },
    portalData: {},
    recordId: '9567',
    modId: '72',
  },
  {
    fieldData: {
      Preview_Calc: 'Florence Household Hazardous Waste Event\r2820 N Rhododendron Dr\rFlorence, OR, 97439\r(541) 682-4120\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Florence Household Hazardous Waste Event',
      Site_Address: '2820 N Rhododendron Dr',
      City: 'Florence',
      Zip: '97439',
      Phone_Line: '(541) 682-4120',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -124.11657200000001,
      Latitude: 43.988168000000002,
      Pin_Code: 'HHW',
      Location_ID: '5993',
      Site_ID_6: 'OR5021',
      County: 'Lane',
    },
    portalData: {},
    recordId: '9568',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Forrest Paint Retail Store\r990 McKinley St\rEugene, OR, 97402\r(541) 868-1222\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Forrest Paint Retail Store',
      Site_Address: '990 McKinley St',
      City: 'Eugene',
      Zip: '97402',
      Phone_Line: '(541) 868-1222',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.1304093,
      Latitude: 44.048874599999998,
      Pin_Code: '20 Gal',
      Location_ID: '193',
      Site_ID_6: 'OR1003',
      County: 'Lane',
    },
    portalData: {},
    recordId: '9569',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Fossil Transfer Station\r17487 Black Butte Rd\rFossil, OR, 97830\r(541) 350-7921\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Fossil Transfer Station',
      Site_Address: '17487 Black Butte Rd',
      City: 'Fossil',
      Zip: '97830',
      Phone_Line: '(541) 350-7921',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -120.14630769999997,
      Latitude: 45.0122585,
      Pin_Code: '20 Gal',
      Location_ID: '6496',
      Site_ID_6: 'OR6013',
      County: 'Wheeler',
    },
    portalData: {},
    recordId: '9570',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Gold Beach Lumber Yard\r16016 Hwy 101 S\rBrookings, OR, 97415\r(541) 469-6617\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Gold Beach Lumber Yard',
      Site_Address: '16016 Hwy 101 S',
      City: 'Brookings',
      Zip: '97415',
      Phone_Line: '(541) 469-6617',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -124.2513524,
      Latitude: 42.043348999999999,
      Pin_Code: '5 Gal',
      Location_ID: '4689',
      Site_ID_6: 'OR1156',
      County: 'Curry',
    },
    portalData: {},
    recordId: '9571',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Gold Beach Lumber Yard\r1935 Oregon St\rPort Orford, OR, 97465\r(541) 366-2222\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Gold Beach Lumber Yard',
      Site_Address: '1935 Oregon St',
      City: 'Port Orford',
      Zip: '97465',
      Phone_Line: '(541) 366-2222',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -124.49738120000001,
      Latitude: 42.754365200000002,
      Pin_Code: '5 Gal',
      Location_ID: '4691',
      Site_ID_6: 'OR1157',
      County: 'Curry',
    },
    portalData: {},
    recordId: '9572',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Gold Beach Lumber Yard Inc\r28680 Hunter Creek Loop\rGold Beach, OR, 97444\r(541) 247-6617\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Gold Beach Lumber Yard Inc',
      Site_Address: '28680 Hunter Creek Loop',
      City: 'Gold Beach',
      Zip: '97444',
      Phone_Line: '(541) 247-6617',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -124.4158831,
      Latitude: 42.385692300000002,
      Pin_Code: '5 Gal',
      Location_ID: '258',
      Site_ID_6: 'OR1076',
      County: 'Curry',
    },
    portalData: {},
    recordId: '9573',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Grant County Hazardous Waste Event\r27941 Lower Yard Rd\rJohn Day, OR, 97845\r(541) 575-0059\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Grant County Hazardous Waste Event',
      Site_Address: '27941 Lower Yard Rd',
      City: 'John Day',
      Zip: '97845',
      Phone_Line: '(541) 575-0059',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -119.001659,
      Latitude: 44.421985999999997,
      Pin_Code: 'HHW',
      Location_ID: '6003',
      Site_ID_6: 'OR5024',
      County: 'Grant',
    },
    portalData: {},
    recordId: '9574',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Grant County Hazardous Waste Event\r27941 Lower Yard Rd\rJohn Day, OR, 97845\r(541) 575-0059\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Grant County Hazardous Waste Event',
      Site_Address: '27941 Lower Yard Rd',
      City: 'John Day',
      Zip: '97845',
      Phone_Line: '(541) 575-0059',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -119.001659,
      Latitude: 44.421985999999997,
      Pin_Code: 'HHW',
      Location_ID: '5912',
      Site_ID_6: 'OR5024',
      County: 'Grant',
    },
    portalData: {},
    recordId: '9575',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Gresham ReStore\r610 NE 181st Ave\rGresham, OR, 97230\r(503) 575-7336\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Gresham ReStore',
      Site_Address: '610 NE 181st Ave',
      City: 'Gresham',
      Zip: '97230',
      Phone_Line: '(503) 575-7336',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.475798,
      Latitude: 45.52728,
      Pin_Code: '20 Gal Reuse',
      Location_ID: '6451',
      Site_ID_6: 'OR6007',
      County: 'Multnomah',
    },
    portalData: {},
    recordId: '9576',
    modId: '72',
  },
  {
    fieldData: {
      Preview_Calc: 'Habitat ReStore Bend Area\r224 NE Thurston Ave\rBend, OR, 97701\r(541) 312-6709\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Habitat ReStore Bend Area',
      Site_Address: '224 NE Thurston Ave',
      City: 'Bend',
      Zip: '97701',
      Phone_Line: '(541) 312-6709',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -121.3035397,
      Latitude: 44.069899200000002,
      Pin_Code: '20 Gal Reuse',
      Location_ID: '263',
      Site_ID_6: 'OR1080',
      County: 'Deschutes',
    },
    portalData: {},
    recordId: '9578',
    modId: '72',
  },
  {
    fieldData: {
      Preview_Calc: 'Halfway PaintCare Event\rHalfway Mercantile Parking Lot\r117 S Main St\rHalfway, OR, 97834\r(855) 724-6809\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\r\r',
      Count: 171,
      Active: 'Y',
      Name: 'Halfway PaintCare Event',
      Site_Address: '117 S Main St',
      City: 'Halfway',
      Zip: '97834',
      Phone_Line: '(855) 724-6809',
      URL: '',
      State: 'OR',
      Name2: 'Halfway Mercantile Parking Lot',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: '',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -117.109533,
      Latitude: 44.876907,
      Pin_Code: '',
      Location_ID: '5530',
      Site_ID_6: 'OR4005',
      County: 'Baker',
    },
    portalData: {},
    recordId: '9579',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Heartwood Resources\r3495 Old Hwy 99 S\rRoseburg, OR, 97471\r(541) 679-1777\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Heartwood Resources',
      Site_Address: '3495 Old Hwy 99 S',
      City: 'Roseburg',
      Zip: '97471',
      Phone_Line: '(541) 679-1777',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.36812999999999,
      Latitude: 43.164098000000003,
      Pin_Code: '20 Gal Reuse',
      Location_ID: '269',
      Site_ID_6: 'OR1092',
      County: 'Douglas',
    },
    portalData: {},
    recordId: '9581',
    modId: '72',
  },
  {
    fieldData: {
      Preview_Calc: 'Hood River Supply\r1945 12th St\rHood River, OR, 97031\r(541) 386-2757\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Hood River Supply',
      Site_Address: '1945 12th St',
      City: 'Hood River',
      Zip: '97031',
      Phone_Line: '(541) 386-2757',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -121.5227737,
      Latitude: 45.693925499999999,
      Pin_Code: '5 Gal',
      Location_ID: '4391',
      Site_ID_6: 'OR1151',
      County: 'Hood River',
    },
    portalData: {},
    recordId: '9582',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Hood River Supply\r3831 Eagle Loop\rOdell, OR, 97031\r(541) 354-3000\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Hood River Supply',
      Site_Address: '3831 Eagle Loop',
      City: 'Odell',
      Zip: '97031',
      Phone_Line: '(541) 354-3000',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -121.544933,
      Latitude: 45.629640700000003,
      Pin_Code: '5 Gal',
      Location_ID: '6455',
      Site_ID_6: 'OR1173',
      County: 'Hood River',
    },
    portalData: {},
    recordId: '9583',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Jerrys Home Improvement Center\r2600 Hwy 99 N\rEugene, OR, 97402\r(541) 689-1911\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Jerrys Home Improvement Center',
      Site_Address: '2600 Hwy 99 N',
      City: 'Eugene',
      Zip: '97402',
      Phone_Line: '(541) 689-1911',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.16666480000001,
      Latitude: 44.097382400000001,
      Pin_Code: '20 Gal',
      Location_ID: '273',
      Site_ID_6: 'OR1097',
      County: 'Lane',
    },
    portalData: {},
    recordId: '9586',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Jerrys Home Improvement Center\r2525 Olympic St\rSpringfield, OR, 97477\r(541) 736-7000\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Jerrys Home Improvement Center',
      Site_Address: '2525 Olympic St',
      City: 'Springfield',
      Zip: '97477',
      Phone_Line: '(541) 736-7000',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.9910122,
      Latitude: 44.057533999999997,
      Pin_Code: '20 Gal',
      Location_ID: '274',
      Site_ID_6: 'OR1098',
      County: 'Lane',
    },
    portalData: {},
    recordId: '9587',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'John Day True Value\r161 E Main St\rJohn Day, OR, 97845\r(541) 575-0632\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'John Day True Value',
      Site_Address: '161 E Main St',
      City: 'John Day',
      Zip: '97845',
      Phone_Line: '(541) 575-0632',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -118.95148450000001,
      Latitude: 44.416075900000003,
      Pin_Code: '5 Gal',
      Location_ID: '196',
      Site_ID_6: 'OR1005',
      County: 'Grant',
    },
    portalData: {},
    recordId: '9588',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Kel Cee Ace Hardware\r1350 Hwy 101 S\rReedsport, OR, 97467\r(541) 271-2741\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Kel Cee Ace Hardware',
      Site_Address: '1350 Hwy 101 S',
      City: 'Reedsport',
      Zip: '97467',
      Phone_Line: '(541) 271-2741',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -124.1091941,
      Latitude: 43.699065500000003,
      Pin_Code: '5 Gal',
      Location_ID: '5778',
      Site_ID_6: 'OR1196',
      County: 'Douglas',
    },
    portalData: {},
    recordId: '9589',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Kerr Ace Hardware Building Center\r711 Chetco Ave\rBrookings, OR, 97415\r(541) 469-3139\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Kerr Ace Hardware Building Center',
      Site_Address: '711 Chetco Ave',
      City: 'Brookings',
      Zip: '97415',
      Phone_Line: '(541) 469-3139',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -124.28420389999999,
      Latitude: 42.0515647,
      Pin_Code: '20 Gal',
      Location_ID: '190',
      Site_ID_6: 'OR6285',
      County: 'Curry',
    },
    portalData: {},
    recordId: '9590',
    modId: '73',
  },
  {
    fieldData: {
      Preview_Calc: 'Kinney Bros And Keele TV Hardware\r460 SW 4th Ave\rPO Box 4249\rOntario, OR, 97914\r(541) 889-7629\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Kinney Bros And Keele TV Hardware',
      Site_Address: '460 SW 4th Ave',
      City: 'Ontario',
      Zip: '97914',
      Phone_Line: '(541) 889-7629',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: 'PO Box 4249',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -116.9698965,
      Latitude: 44.023493999999999,
      Pin_Code: 'Over 20',
      Location_ID: '201',
      Site_ID_6: 'OR1007',
      County: 'Malheur',
    },
    portalData: {},
    recordId: '9591',
    modId: '76',
  },
  {
    fieldData: {
      Preview_Calc: 'Knott Landfill Household Hazardous Waste Facility\r61050 SE 27th St\rBend, OR, 97702\r(541) 317-3163\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Knott Landfill Household Hazardous Waste Facility',
      Site_Address: '61050 SE 27th St',
      City: 'Bend',
      Zip: '97702',
      Phone_Line: '(541) 317-3163',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -121.2571531,
      Latitude: 44.013140200000002,
      Pin_Code: '',
      Location_ID: '4923',
      Site_ID_6: 'OR1178',
      County: 'Deschutes',
    },
    portalData: {},
    recordId: '9592',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Knott Landfill Recycling Center\r61050 SE 27th St\rBend, OR, 97701\r(541) 317-3163\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Knott Landfill Recycling Center',
      Site_Address: '61050 SE 27th St',
      City: 'Bend',
      Zip: '97701',
      Phone_Line: '(541) 317-3163',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -121.2571531,
      Latitude: 44.013140200000002,
      Pin_Code: '20 Gal',
      Location_ID: '5781',
      Site_ID_6: 'OR1178',
      County: 'Deschutes',
    },
    portalData: {},
    recordId: '9593',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'La Grande Paint & Glass\r601 Adams Ave\rLa Grande, OR, 97850\r(541) 963-8671\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'La Grande Paint & Glass',
      Site_Address: '601 Adams Ave',
      City: 'La Grande',
      Zip: '97850',
      Phone_Line: '(541) 963-8671',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -118.10167819999999,
      Latitude: 45.3298086,
      Pin_Code: '20 Gal',
      Location_ID: '5923',
      Site_ID_6: 'OR1197',
      County: 'Union',
    },
    portalData: {},
    recordId: '9594',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'La Pine Sunriver Habitat for Humanity ReStore\r52684 Hwy 97\rLa Pine, OR, 97739\r(541) 536-3234\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'La Pine Sunriver Habitat for Humanity ReStore',
      Site_Address: '52684 Hwy 97',
      City: 'La Pine',
      Zip: '97739',
      Phone_Line: '(541) 536-3234',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -121.473129,
      Latitude: 43.713638000000003,
      Pin_Code: 'Over 20 Reuse',
      Location_ID: '6133',
      Site_ID_6: 'OR1182',
      County: 'Deschutes',
    },
    portalData: {},
    recordId: '9595',
    modId: '72',
  },
  {
    fieldData: {
      Preview_Calc: 'Lake County Road Department Household Hazardous Waste Event\r95574 Hwy 140 W\rLakeview, OR, 97630\r(541) 575-0059\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\r\r',
      Count: 171,
      Active: 'Y0',
      Name: 'Lake County Road Department Household Hazardous Waste Event',
      Site_Address: '95574 Hwy 140 W',
      City: 'Lakeview',
      Zip: '97630',
      Phone_Line: '(541) 575-0059',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: '',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -120.3948778,
      Latitude: 42.193351800000002,
      Pin_Code: '',
      Location_ID: '5928',
      Site_ID_6: 'OR4002',
      County: 'Lake',
    },
    portalData: {},
    recordId: '9596',
    modId: '67',
  },
  {
    fieldData: {
      Preview_Calc: 'LaPine Ace Hardware And Building Supply\r51615 Huntington Rd\rLa Pine, OR, 97739\r(541) 536-2161\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'LaPine Ace Hardware And Building Supply',
      Site_Address: '51615 Huntington Rd',
      City: 'La Pine',
      Zip: '97739',
      Phone_Line: '(541) 536-2161',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -121.50465579999999,
      Latitude: 43.675043799999997,
      Pin_Code: '5 Gal',
      Location_ID: '220',
      Site_ID_6: 'OR1029',
      County: 'Deschutes',
    },
    portalData: {},
    recordId: '9599',
    modId: '72',
  },
  {
    fieldData: {
      Preview_Calc: 'Lebanon Household Hazardous Waste Event\r410 Crowfoot Rd\rLebanon, OR, 97355\r(541) 928-2551\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Lebanon Household Hazardous Waste Event',
      Site_Address: '410 Crowfoot Rd',
      City: 'Lebanon',
      Zip: '97355',
      Phone_Line: '(541) 928-2551',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.8903071,
      Latitude: 44.508290000000002,
      Pin_Code: 'HHW',
      Location_ID: '5996',
      Site_ID_6: 'OR5030',
      County: 'Linn',
    },
    portalData: {},
    recordId: '9600',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Lebanon ReStore\r566 Main St\rLebanon, OR, 97355\r(541) 451-1234\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Lebanon ReStore',
      Site_Address: '566 Main St',
      City: 'Lebanon',
      Zip: '97355',
      Phone_Line: '(541) 451-1234',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.90802189999999,
      Latitude: 44.549683000000002,
      Pin_Code: '5 Gal Reuse',
      Location_ID: '221',
      Site_ID_6: 'OR1030',
      County: 'Linn',
    },
    portalData: {},
    recordId: '9601',
    modId: '72',
  },
  {
    fieldData: {
      Preview_Calc: 'Lincoln City ReStore\r2150 SE Hwy 101\rLincoln City, OR, 97367\r(541) 614-1060\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Lincoln City ReStore',
      Site_Address: '2150 SE Hwy 101',
      City: 'Lincoln City',
      Zip: '97367',
      Phone_Line: '(541) 614-1060',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -124.0133694,
      Latitude: 44.9527997,
      Pin_Code: 'Over 20',
      Location_ID: '5801',
      Site_ID_6: 'OR1187',
      County: 'Lincoln',
    },
    portalData: {},
    recordId: '9602',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Lincoln County Dahl Sanitary Service\rSouth Lincoln Recycling & Transfer Center\r235 SW Dahl Ave\rWaldport, OR, 97394\r(541) 563-3888\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Lincoln County Dahl Sanitary Service',
      Site_Address: '235 SW Dahl Ave',
      City: 'Waldport',
      Zip: '97394',
      Phone_Line: '(541) 563-3888',
      URL: '',
      State: 'OR',
      Name2: 'South Lincoln Recycling & Transfer Center',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -124.06716710000001,
      Latitude: 44.402720100000003,
      Pin_Code: '20 Gal',
      Location_ID: '281',
      Site_ID_6: 'OR1085',
      County: 'Lincoln',
    },
    portalData: {},
    recordId: '9603',
    modId: '73',
  },
  {
    fieldData: {
      Preview_Calc: 'Lincoln County North Lincoln Sanitary Service\rNorth Lincoln Sanitary Service\r1726 SE Hwy 101\rLincoln City, OR, 97367\r(541) 994-5555\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Lincoln County North Lincoln Sanitary Service',
      Site_Address: '1726 SE Hwy 101',
      City: 'Lincoln City',
      Zip: '97367',
      Phone_Line: '(541) 994-5555',
      URL: '',
      State: 'OR',
      Name2: 'North Lincoln Sanitary Service',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -124.0137696,
      Latitude: 44.955667499999997,
      Pin_Code: '5 Gal',
      Location_ID: '267',
      Site_ID_6: 'OR1086',
      County: 'Lincoln',
    },
    portalData: {},
    recordId: '9604',
    modId: '73',
  },
  {
    fieldData: {
      Preview_Calc: 'Lincoln County Schooner Creek Transfer Station\r367 S Anderson Creek Rd\rLincoln City, OR, 97367\r(541) 265-4171\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Lincoln County Schooner Creek Transfer Station',
      Site_Address: '367 S Anderson Creek Rd',
      City: 'Lincoln City',
      Zip: '97367',
      Phone_Line: '(541) 265-4171',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.974761,
      Latitude: 44.932298799999998,
      Pin_Code: '5 Gal',
      Location_ID: '266',
      Site_ID_6: 'OR1087',
      County: 'Lincoln City',
    },
    portalData: {},
    recordId: '9605',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sisters Ace Hardware\rformerly Luttons Ace Hardware\r373 E Hood Ave\rSisters, OR, 97759\r(541) 549-8011\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sisters Ace Hardware',
      Site_Address: '373 E Hood Ave',
      City: 'Sisters',
      Zip: '97759',
      Phone_Line: '(541) 549-8011',
      URL: '',
      State: 'OR',
      Name2: 'formerly Luttons Ace Hardware',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -121.5466629,
      Latitude: 44.290203200000001,
      Pin_Code: '5 Gal',
      Location_ID: '224',
      Site_ID_6: 'OR1031',
      County: 'Deschutes',
    },
    portalData: {},
    recordId: '9606',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Madras Paint & Glass\r1076 SW Hwy 97\rMadras, OR, 97741\r(541) 475-2166\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Madras Paint & Glass',
      Site_Address: '1076 SW Hwy 97',
      City: 'Madras',
      Zip: '97741',
      Phone_Line: '(541) 475-2166',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -121.1322042,
      Latitude: 44.620567999999999,
      Pin_Code: '20 Gal',
      Location_ID: '4350',
      Site_ID_6: 'OR1116',
      County: 'Jefferson',
    },
    portalData: {},
    recordId: '9607',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Manzanita Transfer Station\r34995 Necarney City Rd\rManzanita, OR, 97130\r(503) 368-7764\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Manzanita Transfer Station',
      Site_Address: '34995 Necarney City Rd',
      City: 'Manzanita',
      Zip: '97130',
      Phone_Line: '(503) 368-7764',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.92717,
      Latitude: 45.71152,
      Pin_Code: '20 Gal',
      Location_ID: '5547',
      Site_ID_6: 'OR6176',
      County: 'Tillamook',
    },
    portalData: {},
    recordId: '9608',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'McMinnville Area ReStore\r1040 SE 1st St\rMcMinnville, OR, 97128\r(503) 434-6810\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'McMinnville Area ReStore',
      Site_Address: '1040 SE 1st St',
      City: 'McMinnville',
      Zip: '97128',
      Phone_Line: '(503) 434-6810',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.1876358,
      Latitude: 45.208388399999997,
      Pin_Code: 'Over 20 Reuse',
      Location_ID: '198',
      Site_ID_6: 'OR1008',
      County: 'Yamhill',
    },
    portalData: {},
    recordId: '9610',
    modId: '72',
  },
  {
    fieldData: {
      Preview_Calc: 'Metro Central Household Hazardous Waste Facility\rMetro Central Transfer Station\r6161 NW 61st\rPortland, OR, 97201\r(503) 234-3000\r\rWho Can Use This Program \rFor Households and Some Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the site for details and restrictions. Please do not bring empty, leaking or unlabeled containers. SOME BUSINESSES may use this program by appointment or on special days. Contact the program for details if your paint (or other hazardous waste) is from a business.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Metro Central Household Hazardous Waste Facility',
      Site_Address: '6161 NW 61st',
      City: 'Portland',
      Zip: '97201',
      Phone_Line: '(503) 234-3000',
      URL: '',
      State: 'OR',
      Name2: 'Metro Central Transfer Station',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the site for details and restrictions. Please do not bring empty, leaking or unlabeled containers. SOME BUSINESSES may use this program by appointment or on special days. Contact the program for details if your paint (or other hazardous waste) is from a business.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.7475695,
      Latitude: 45.567769900000002,
      Pin_Code: 'HHW',
      Location_ID: '204',
      Site_ID_6: 'OR1009',
      County: 'Multnomah',
    },
    portalData: {},
    recordId: '9611',
    modId: '76',
  },
  {
    fieldData: {
      Preview_Calc: 'Metro South Household Hazardous Waste Facility\rMetro South Transfer Station\r2001 Washington St\rOregon City, OR, 97045\r(503) 234-3000\r\rWho Can Use This Program \rFor Households and Some Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the site for details and restrictions. Please do not bring empty, leaking or unlabeled containers. SOME BUSINESSES may use this program by appointment or on special days. Contact the program for details if your paint (or other hazardous waste) is from a business.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Metro South Household Hazardous Waste Facility',
      Site_Address: '2001 Washington St',
      City: 'Oregon City',
      Zip: '97045',
      Phone_Line: '(503) 234-3000',
      URL: '',
      State: 'OR',
      Name2: 'Metro South Transfer Station',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the site for details and restrictions. Please do not bring empty, leaking or unlabeled containers. SOME BUSINESSES may use this program by appointment or on special days. Contact the program for details if your paint (or other hazardous waste) is from a business.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.58921100000001,
      Latitude: 45.370590399999998,
      Pin_Code: 'HHW',
      Location_ID: '202',
      Site_ID_6: 'OR1010',
      County: 'Clackamas',
    },
    portalData: {},
    recordId: '9612',
    modId: '74',
  },
  {
    fieldData: {
      Preview_Calc: 'Mid Willamette Valley ReStore\r1249 13th St SE\rSalem, OR, 97302\r(503) 485-4845\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Mid Willamette Valley ReStore',
      Site_Address: '1249 13th St SE',
      City: 'Salem',
      Zip: '97302',
      Phone_Line: '(503) 485-4845',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.02926890000001,
      Latitude: 44.925270400000002,
      Pin_Code: '20 Gal Reuse',
      Location_ID: '3456',
      Site_ID_6: 'OR1108',
      County: 'Marion',
    },
    portalData: {},
    recordId: '9613',
    modId: '72',
  },
  {
    fieldData: {
      Preview_Calc: 'Miller Paint\r1620 D Allen Creek Rd\rGrants Pass, OR, 97527\r(541) 244-1723\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Miller Paint',
      Site_Address: '1620 D Allen Creek Rd',
      City: 'Grants Pass',
      Zip: '97527',
      Phone_Line: '(541) 244-1723',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.3515725,
      Latitude: 42.419814600000002,
      Pin_Code: '20 Gal',
      Location_ID: '4331',
      Site_ID_6: 'OR1140',
      County: 'Josephine',
    },
    portalData: {},
    recordId: '9614',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Miller Paint\r317 SE Grand Ave\rPortland, OR, 97214\r(503) 233-4491\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Miller Paint',
      Site_Address: '317 SE Grand Ave',
      City: 'Portland',
      Zip: '97214',
      Phone_Line: '(503) 233-4491',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.6612398,
      Latitude: 45.520589000000001,
      Pin_Code: '20 Gal',
      Location_ID: '4392',
      Site_ID_6: 'OR1139',
      County: 'Multnomah',
    },
    portalData: {},
    recordId: '9615',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Miller Paint\r1405 NW 9th St\rCorvallis, OR, 97330\r(541) 758-4458\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Miller Paint',
      Site_Address: '1405 NW 9th St',
      City: 'Corvallis',
      Zip: '97330',
      Phone_Line: '(541) 758-4458',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.25915790000001,
      Latitude: 44.579887599999999,
      Pin_Code: '20 Gal',
      Location_ID: '4528',
      Site_ID_6: 'OR1142',
      County: 'Benton',
    },
    portalData: {},
    recordId: '9616',
    modId: '72',
  },
  {
    fieldData: {
      Preview_Calc: 'Miller Paint\r3520 W 11th Ave\rEugene, OR, 97402\r(541) 431-4444\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Miller Paint',
      Site_Address: '3520 W 11th Ave',
      City: 'Eugene',
      Zip: '97402',
      Phone_Line: '(541) 431-4444',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.1437097,
      Latitude: 44.047714399999997,
      Pin_Code: '20 Gal',
      Location_ID: '4529',
      Site_ID_6: 'OR1143',
      County: 'Lane',
    },
    portalData: {},
    recordId: '9617',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Miller Paint\r390 Lancaster Dr NE\rSalem, OR, 97301\r(503) 364-2264\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Miller Paint',
      Site_Address: '390 Lancaster Dr NE',
      City: 'Salem',
      Zip: '97301',
      Phone_Line: '(503) 364-2264',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.98269139999999,
      Latitude: 44.9327714,
      Pin_Code: '20 Gal',
      Location_ID: '4606',
      Site_ID_6: 'OR1138',
      County: 'Marion',
    },
    portalData: {},
    recordId: '9618',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Miller Paint\r8073 SE 17th Ave\rPortland, OR, 97202\r(503) 290-3580\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Miller Paint',
      Site_Address: '8073 SE 17th Ave',
      City: 'Portland',
      Zip: '97202',
      Phone_Line: '(503) 290-3580',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.6474068,
      Latitude: 45.464536799999998,
      Pin_Code: '5 Gal',
      Location_ID: '4688',
      Site_ID_6: 'OR1160',
      County: 'Multnomah',
    },
    portalData: {},
    recordId: '9620',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Miller Paint\r1350 Pacific Blvd\rAlbany, OR, 97321\r(541) 791-1851\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Miller Paint',
      Site_Address: '1350 Pacific Blvd',
      City: 'Albany',
      Zip: '97321',
      Phone_Line: '(541) 791-1851',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.08790430000001,
      Latitude: 44.633673199999997,
      Pin_Code: '20 Gal',
      Location_ID: '4645',
      Site_ID_6: 'OR1141',
      County: 'Linn',
    },
    portalData: {},
    recordId: '9621',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Miller Paint\r2205 Ashland St\rSte 101\rAshland, OR, 97520\r(541) 482-4002\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Miller Paint',
      Site_Address: '2205 Ashland St',
      City: 'Ashland',
      Zip: '97520',
      Phone_Line: '(541) 482-4002',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: 'Ste 101',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.67424,
      Latitude: 42.185898000000002,
      Pin_Code: '5 Gal',
      Location_ID: '187',
      Site_ID_6: 'OR1011',
      County: 'Jackson',
    },
    portalData: {},
    recordId: '9623',
    modId: '74',
  },
  {
    fieldData: {
      Preview_Calc: 'Miller Paint\r1831 E Powell Blvd\rGresham, OR, 97080\r(503) 666-9018\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Miller Paint',
      Site_Address: '1831 E Powell Blvd',
      City: 'Gresham',
      Zip: '97080',
      Phone_Line: '(503) 666-9018',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.4120528,
      Latitude: 45.497361699999999,
      Pin_Code: '20 Gal',
      Location_ID: '195',
      Site_ID_6: 'OR1013',
      County: 'Multnomah',
    },
    portalData: {},
    recordId: '9624',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Miller Paint\r544 N State St\rLake Oswego, OR, 97034\r(971) 204-0007\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Miller Paint',
      Site_Address: '544 N State St',
      City: 'Lake Oswego',
      Zip: '97034',
      Phone_Line: '(971) 204-0007',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.66288849999999,
      Latitude: 45.421075299999998,
      Pin_Code: '5 Gal',
      Location_ID: '237',
      Site_ID_6: 'OR1053',
      County: 'Clackamas',
    },
    portalData: {},
    recordId: '9625',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Miller Paint\r10210 SE Hwy 212\rClackamas, OR, 97015\r(503) 607-0156\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Miller Paint',
      Site_Address: '10210 SE Hwy 212',
      City: 'Clackamas',
      Zip: '97015',
      Phone_Line: '(503) 607-0156',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.5582648,
      Latitude: 45.407486200000001,
      Pin_Code: '20 Gal',
      Location_ID: '239',
      Site_ID_6: 'OR1059',
      County: 'Clackamas',
    },
    portalData: {},
    recordId: '9626',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Miller Paint\r1040 NW Murray Rd\rPortland, OR, 97229\r(503) 574-4411\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Miller Paint',
      Site_Address: '1040 NW Murray Rd',
      City: 'Portland',
      Zip: '97229',
      Phone_Line: '(503) 574-4411',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.8132054,
      Latitude: 45.527020899999997,
      Pin_Code: '20 Gal',
      Location_ID: '249',
      Site_ID_6: 'OR1068',
      County: 'Multnomah',
    },
    portalData: {},
    recordId: '9627',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Miller Paint\r8703 SW Beaverton Hillsdale Hwy\rPortland, OR, 97202\r(503) 292-4444\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Miller Paint',
      Site_Address: '8703 SW Beaverton Hillsdale Hwy',
      City: 'Portland',
      Zip: '97202',
      Phone_Line: '(503) 292-4444',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.7664315,
      Latitude: 45.486773700000001,
      Pin_Code: '20 Gal',
      Location_ID: '250',
      Site_ID_6: 'OR1069',
      County: 'Multnomah',
    },
    portalData: {},
    recordId: '9628',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Miller Paint\r646 SW Oak St\rHillsboro, OR, 97123\r(503) 648-7953\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Miller Paint',
      Site_Address: '646 SW Oak St',
      City: 'Hillsboro',
      Zip: '97123',
      Phone_Line: '(503) 648-7953',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.9970367,
      Latitude: 45.518389399999997,
      Pin_Code: '5 Gal',
      Location_ID: '4906',
      Site_ID_6: 'OR1144',
      County: 'Multnomah',
    },
    portalData: {},
    recordId: '9629',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Millers Home Center And Lumber\r3109 May Ln\rLa Grande, OR, 97850\r(541) 963-2613\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Millers Home Center And Lumber',
      Site_Address: '3109 May Ln',
      City: 'La Grande',
      Zip: '97850',
      Phone_Line: '(541) 963-2613',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -118.068489,
      Latitude: 45.335533,
      Pin_Code: '5 Gal',
      Location_ID: '7042',
      Site_ID_6: 'OR6225',
      County: 'Union',
    },
    portalData: {},
    recordId: '9630',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Millers Home Center And Lumber\r3815 Pocahontas Rd\rBaker City, OR, 97814\r(541) 523-6404\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Millers Home Center And Lumber',
      Site_Address: '3815 Pocahontas Rd',
      City: 'Baker City',
      Zip: '97814',
      Phone_Line: '(541) 523-6404',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -117.8552356,
      Latitude: 44.796151199999997,
      Pin_Code: '20 Gal',
      Location_ID: '212',
      Site_ID_6: 'OR1032',
      County: 'Baker',
    },
    portalData: {},
    recordId: '9632',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Mitchell Transfer Station\rMile Post 24.5 Hwy 207\rMitchell, OR, 97750\r(541) 350-7921\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Mitchell Transfer Station',
      Site_Address: 'Mile Post 24.5 Hwy 207',
      City: 'Mitchell',
      Zip: '97750',
      Phone_Line: '(541) 350-7921',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -120.169202,
      Latitude: 44.579482,
      Pin_Code: '20 Gal',
      Location_ID: '6497',
      Site_ID_6: 'OR6015',
      County: 'Wheeler',
    },
    portalData: {},
    recordId: '9633',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Morrow County North Transfer Station\rNorth Transfer Station\r69900 Frontage Ln\rBoardman, OR, 97818\r(541) 989-9500\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Morrow County North Transfer Station',
      Site_Address: '69900 Frontage Ln',
      City: 'Boardman',
      Zip: '97818',
      Phone_Line: '(541) 989-9500',
      URL: '',
      State: 'OR',
      Name2: 'North Transfer Station',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -119.691765,
      Latitude: 45.840237000000002,
      Pin_Code: '20 Gal',
      Location_ID: '3458',
      Site_ID_6: 'OR1089',
      County: 'Morrow',
    },
    portalData: {},
    recordId: '9634',
    modId: '73',
  },
  {
    fieldData: {
      Preview_Calc: 'MSK Building Supply\r102 S Old Pacific Hwy\rMyrtle Creek, OR, 97457\r(541) 863-3127\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'MSK Building Supply',
      Site_Address: '102 S Old Pacific Hwy',
      City: 'Myrtle Creek',
      Zip: '97457',
      Phone_Line: '(541) 863-3127',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.320925,
      Latitude: 42.985126999999999,
      Pin_Code: '20 Gal',
      Location_ID: '3455',
      Site_ID_6: 'OR1106',
      County: 'Douglas',
    },
    portalData: {},
    recordId: '9636',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'North Marion Recycling and Transfer Station\r17827 Whitney Ln NE\rWoodburn, OR, 97071\r(503) 588-5169\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'North Marion Recycling and Transfer Station',
      Site_Address: '17827 Whitney Ln NE',
      City: 'Woodburn',
      Zip: '97071',
      Phone_Line: '(503) 588-5169',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.8581762,
      Latitude: 45.178195100000003,
      Pin_Code: '5 Gal',
      Location_ID: '279',
      Site_ID_6: 'OR1100',
      County: 'Marion',
    },
    portalData: {},
    recordId: '9637',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'North Willamette Restore\r245 Young St\rWoodburn, OR, 97071\r(503) 845-4161\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'North Willamette Restore',
      Site_Address: '245 Young St',
      City: 'Woodburn',
      Zip: '97071',
      Phone_Line: '(503) 845-4161',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.855008,
      Latitude: 45.141607999999998,
      Pin_Code: '20 Gal Reuse',
      Location_ID: '270',
      Site_ID_6: 'OR1093',
      County: 'Marion',
    },
    portalData: {},
    recordId: '9638',
    modId: '73',
  },
  {
    fieldData: {
      Preview_Calc: 'Pacific City True Value Hardware\r34995 River Ave\rPacific City, OR, 97135\r(503) 965-6295\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Pacific City True Value Hardware',
      Site_Address: '34995 River Ave',
      City: 'Pacific City',
      Zip: '97135',
      Phone_Line: '(503) 965-6295',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.9632684,
      Latitude: 45.202269299999998,
      Pin_Code: '5 Gal',
      Location_ID: '5238',
      Site_ID_6: 'OR1177',
      County: 'Tillamook',
    },
    portalData: {},
    recordId: '9643',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'PaintCare Drop-Off Event\rHumbert Refuse & Recycling\r54841 Hwy 332\rMilton-Freewater, OR, 97862\r(855) 724-6809\r\rWebsite\rhttp://www.paintcare.org/miltonfreewater/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\r\r',
      Count: 171,
      Active: 'Y',
      Name: 'PaintCare Drop-Off Event',
      Site_Address: '54841 Hwy 332',
      City: 'Milton-Freewater',
      Zip: '97862',
      Phone_Line: '(855) 724-6809',
      URL: 'http://www.paintcare.org/miltonfreewater/',
      State: 'OR',
      Name2: 'Humbert Refuse & Recycling',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: '',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -118.3916954,
      Latitude: 45.961984000000001,
      Pin_Code: '',
      Location_ID: '6162',
      Site_ID_6: 'OR4012',
      County: 'Wallowa',
    },
    portalData: {},
    recordId: '9645',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'PaintCare Drop-Off Event\rHalfway Mercantile\r117 S Main St\rHalfway, OR, 97834\r(855) 724-6809\r\rWebsite\rhttp://www.paintcare.org/halfway/\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\r\r',
      Count: 171,
      Active: 'Y',
      Name: 'PaintCare Drop-Off Event',
      Site_Address: '117 S Main St',
      City: 'Halfway',
      Zip: '97834',
      Phone_Line: '(855) 724-6809',
      URL: 'http://www.paintcare.org/halfway/',
      State: 'OR',
      Name2: 'Halfway Mercantile',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: '',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -117.109533,
      Latitude: 44.876907,
      Pin_Code: '',
      Location_ID: '6163',
      Site_ID_6: 'OR4005',
      County: 'Wallowa',
    },
    portalData: {},
    recordId: '9646',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'PaintCare One Day Event\rUmatilla Electric Co Op\r750 W Elm\rHermiston, OR, 97838\r\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'PaintCare One Day Event',
      Site_Address: '750 W Elm',
      City: 'Hermiston',
      Zip: '97838',
      Phone_Line: '',
      URL: '',
      State: 'OR',
      Name2: 'Umatilla Electric Co Op',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -119.18069,
      Latitude: 45.51003,
      Pin_Code: 'HHW',
      Location_ID: '6004',
      Site_ID_6: 'OR5072',
      County: 'Umatilla',
    },
    portalData: {},
    recordId: '9647',
    modId: '74',
  },
  {
    fieldData: {
      Preview_Calc: 'Parkrose True Value Hardware\r10625 NE Sandy Blvd\rPortland, OR, 97220\r(503) 256-3103\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Parkrose True Value Hardware',
      Site_Address: '10625 NE Sandy Blvd',
      City: 'Portland',
      Zip: '97220',
      Phone_Line: '(503) 256-3103',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.5535457,
      Latitude: 45.559322199999997,
      Pin_Code: '5 Gal',
      Location_ID: '244',
      Site_ID_6: 'OR1062',
      County: 'Multnomah',
    },
    portalData: {},
    recordId: '9648',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Parr Lumber\r601 N Main St\rPrineville, OR, 97754\r(541) 447-7217 or (541) 323-3131\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Parr Lumber',
      Site_Address: '601 N Main St',
      City: 'Prineville',
      Zip: '97754',
      Phone_Line: '(541) 447-7217 or (541) 323-3131',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -120.8477364,
      Latitude: 44.305722199999998,
      Pin_Code: '5 Gal',
      Location_ID: '206',
      Site_ID_6: 'OR1015',
      County: 'Crook',
    },
    portalData: {},
    recordId: '9649',
    modId: '84',
  },
  {
    fieldData: {
      Preview_Calc: 'Parr Lumber\r1 S Broadway Ave\rBurns, OR, 97720\r(541) 573-5211\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Parr Lumber',
      Site_Address: '1 S Broadway Ave',
      City: 'Burns',
      Zip: '97720',
      Phone_Line: '(541) 573-5211',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -119.0544373,
      Latitude: 43.586022999999997,
      Pin_Code: '20 Gal',
      Location_ID: '191',
      Site_ID_6: 'OR1016',
      County: 'Harney',
    },
    portalData: {},
    recordId: '9650',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Parr Lumber\r1231 Disk Dr\rMedford, OR, 97501\r(541) 734-7277\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Parr Lumber',
      Site_Address: '1231 Disk Dr',
      City: 'Medford',
      Zip: '97501',
      Phone_Line: '(541) 734-7277',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.89539689999999,
      Latitude: 42.338941699999999,
      Pin_Code: '20 Gal',
      Location_ID: '264',
      Site_ID_6: 'OR1082',
      County: 'Jackson',
    },
    portalData: {},
    recordId: '9651',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Parr Lumber\r21700 NW Wagon Way\rHillsboro, OR, 97124\r(503) 690-7344\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Parr Lumber',
      Site_Address: '21700 NW Wagon Way',
      City: 'Hillsboro',
      Zip: '97124',
      Phone_Line: '(503) 690-7344',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.90134329999999,
      Latitude: 45.558188399999999,
      Pin_Code: '5 Gal',
      Location_ID: '5130',
      Site_ID_6: 'OR1171',
      County: 'Washington',
    },
    portalData: {},
    recordId: '9655',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Parr Lumber\r200 N Eliott Rd\rNewberg, OR, 97132\r(503) 554-7277\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Parr Lumber',
      Site_Address: '200 N Eliott Rd',
      City: 'Newberg',
      Zip: '97132',
      Phone_Line: '(503) 554-7277',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.9530118,
      Latitude: 45.301687000000001,
      Pin_Code: '5 Gal',
      Location_ID: '4940',
      Site_ID_6: 'OR1166',
      County: 'Yamhill',
    },
    portalData: {},
    recordId: '9659',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Parr Lumber\r415 1st Ave E\rAlbany, OR, 97321\r(541) 926-1525\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Parr Lumber',
      Site_Address: '415 1st Ave E',
      City: 'Albany',
      Zip: '97321',
      Phone_Line: '(541) 926-1525',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.1011025,
      Latitude: 44.638184799999998,
      Pin_Code: '20 Gal',
      Location_ID: '4979',
      Site_ID_6: 'OR1164',
      County: 'Linn',
    },
    portalData: {},
    recordId: '9660',
    modId: '72',
  },
  {
    fieldData: {
      Preview_Calc: 'Parr Lumber\r4050 Salem Industrial Dr NE\rSalem, OR, 97301\r(503) 390-9193\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Parr Lumber',
      Site_Address: '4050 Salem Industrial Dr NE',
      City: 'Salem',
      Zip: '97301',
      Phone_Line: '(503) 390-9193',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.0040542,
      Latitude: 44.983530399999999,
      Pin_Code: '5 Gal',
      Location_ID: '5097',
      Site_ID_6: 'OR1165',
      County: 'Marion',
    },
    portalData: {},
    recordId: '9661',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Parr Lumber\r1291 South A St\rSpringfield, OR, 97747\r(541) 345-7277\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Parr Lumber',
      Site_Address: '1291 South A St',
      City: 'Springfield',
      Zip: '97747',
      Phone_Line: '(541) 345-7277',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.007608,
      Latitude: 44.044309,
      Pin_Code: '20 Gal',
      Location_ID: '6613',
      Site_ID_6: 'OR6080',
      County: 'Lane',
    },
    portalData: {},
    recordId: '9663',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Pendleton Convention Center Household Hazardous Waste Event\r1601 Westgate\rPendleton, OR, 97801\r(360) 607-5434\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Pendleton Convention Center Household Hazardous Waste Event',
      Site_Address: '1601 Westgate',
      City: 'Pendleton',
      Zip: '97801',
      Phone_Line: '(360) 607-5434',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -118.8030144,
      Latitude: 45.6695013,
      Pin_Code: 'HHW',
      Location_ID: '5924',
      Site_ID_6: 'OR5025',
      County: 'Umatilla',
    },
    portalData: {},
    recordId: '9664',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Portland ReStore\r10445 SE Cherry Blossom Dr\rPortland, OR, 97216\r(503) 283-6247\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Portland ReStore',
      Site_Address: '10445 SE Cherry Blossom Dr',
      City: 'Portland',
      Zip: '97216',
      Phone_Line: '(503) 283-6247',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.5561665,
      Latitude: 45.516610800000002,
      Pin_Code: 'Over 20 Reuse',
      Location_ID: '205',
      Site_ID_6: 'OR1017',
      County: 'Multnomah',
    },
    portalData: {},
    recordId: '9667',
    modId: '72',
  },
  {
    fieldData: {
      Preview_Calc: 'Powell Paint Center\r5205 SE Powell Blvd\rPortland, OR, 97206\r(503) 775-3642\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Powell Paint Center',
      Site_Address: '5205 SE Powell Blvd',
      City: 'Portland',
      Zip: '97206',
      Phone_Line: '(503) 775-3642',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.6085966,
      Latitude: 45.497643500000002,
      Pin_Code: '5 Gal',
      Location_ID: '272',
      Site_ID_6: 'OR1095',
      County: 'Multnomah',
    },
    portalData: {},
    recordId: '9668',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'PPG Paints\r310 NE Beacon St\rGrants Pass, OR, 97526\r(541) 479-2546\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'PPG Paints',
      Site_Address: '310 NE Beacon St',
      City: 'Grants Pass',
      Zip: '97526',
      Phone_Line: '(541) 479-2546',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.3077007,
      Latitude: 42.438727700000001,
      Pin_Code: '5 Gal',
      Location_ID: '252',
      Site_ID_6: 'OR1071',
      County: 'Josephine',
    },
    portalData: {},
    recordId: '9670',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'PPG Paints\r955 SE Wilson Ave\rBend, OR, 97702\r(541) 382-4020\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'PPG Paints',
      Site_Address: '955 SE Wilson Ave',
      City: 'Bend',
      Zip: '97702',
      Phone_Line: '(541) 382-4020',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -121.2914147,
      Latitude: 44.0437516,
      Pin_Code: '20 Gal',
      Location_ID: '4887',
      Site_ID_6: 'OR1162',
      County: 'Deschutes',
    },
    portalData: {},
    recordId: '9671',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'PPG Paints\r1830 W 7th Ave\rEugene, OR, 97402\r(541) 342-1148\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'PPG Paints',
      Site_Address: '1830 W 7th Ave',
      City: 'Eugene',
      Zip: '97402',
      Phone_Line: '(541) 342-1148',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.121432,
      Latitude: 44.051807599999997,
      Pin_Code: '20 Gal',
      Location_ID: '4649',
      Site_ID_6: 'OR1136',
      County: 'Lane',
    },
    portalData: {},
    recordId: '9672',
    modId: '72',
  },
  {
    fieldData: {
      Preview_Calc: 'PPG Paints\r2720 Crater Lake Hwy\rMedford, OR, 97504\r(541) 772-7107\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'PPG Paints',
      Site_Address: '2720 Crater Lake Hwy',
      City: 'Medford',
      Zip: '97504',
      Phone_Line: '(541) 772-7107',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.8649905,
      Latitude: 42.356831499999998,
      Pin_Code: '20 Gal',
      Location_ID: '4332',
      Site_ID_6: 'OR1137',
      County: 'Jackson',
    },
    portalData: {},
    recordId: '9673',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Prineville Reuse Store\r1427 NW Murphy Ct\rPrineville, OR, 97754\r(541) 447-6934\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Prineville Reuse Store',
      Site_Address: '1427 NW Murphy Ct',
      City: 'Prineville',
      Zip: '97754',
      Phone_Line: '(541) 447-6934',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -120.86491030000001,
      Latitude: 44.315476699999998,
      Pin_Code: '20 Gal Reuse',
      Location_ID: '4678',
      Site_ID_6: 'OR1159',
      County: 'Crook',
    },
    portalData: {},
    recordId: '9674',
    modId: '72',
  },
  {
    fieldData: {
      Preview_Calc: 'Rainier Household Hazardous Waste Event\r690 West A St\rRainier, OR, 97048\r(503) 397-7259\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Rainier Household Hazardous Waste Event',
      Site_Address: '690 West A St',
      City: 'Rainier',
      Zip: '97048',
      Phone_Line: '(503) 397-7259',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.94558480000001,
      Latitude: 46.092007899999999,
      Pin_Code: 'HHW',
      Location_ID: '5989',
      Site_ID_6: 'OR5012',
      County: 'Columbia',
    },
    portalData: {},
    recordId: '9675',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Republic Services HHW Event\r110 NE Walnut Blvd\rCorvallis, OR, 97330\r(541) 754-0444\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Republic Services HHW Event',
      Site_Address: '110 NE Walnut Blvd',
      City: 'Corvallis',
      Zip: '97330',
      Phone_Line: '(541) 754-0444',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.2506846,
      Latitude: 44.592235299999999,
      Pin_Code: 'HHW',
      Location_ID: '5985',
      Site_ID_6: 'OR5009',
      County: 'Benton',
    },
    portalData: {},
    recordId: '9677',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Rockhorse Park\r74543 Oregon 31\rFort Rock, OR, 97735\r(541) 576-2488\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Rockhorse Park',
      Site_Address: '74543 Oregon 31',
      City: 'Fort Rock',
      Zip: '97735',
      Phone_Line: '(541) 576-2488',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -121.1819136,
      Latitude: 43.356869099999997,
      Pin_Code: '20 Gal',
      Location_ID: '5672',
      Site_ID_6: 'OR1184',
      County: 'Lake',
    },
    portalData: {},
    recordId: '9678',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Rodda Paint\r10309 SE 82nd Ave\rHappy Valley, OR, 97086\r(503) 788-0797\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Rodda Paint',
      Site_Address: '10309 SE 82nd Ave',
      City: 'Happy Valley',
      Zip: '97086',
      Phone_Line: '(503) 788-0797',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.579746,
      Latitude: 45.448664299999997,
      Pin_Code: '5 Gal',
      Location_ID: '5606',
      Site_ID_6: 'OR1185',
      County: 'Clackamas',
    },
    portalData: {},
    recordId: '9679',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Rodda Paint\r2229 Lancaster Dr NE\rSalem, OR, 97305\r(503) 779-7848\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Rodda Paint',
      Site_Address: '2229 Lancaster Dr NE',
      City: 'Salem',
      Zip: '97305',
      Phone_Line: '(503) 779-7848',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.9834,
      Latitude: 44.97552,
      Pin_Code: '5 Gal',
      Location_ID: '7027',
      Site_ID_6: 'OR6200',
      County: 'Marion',
    },
    portalData: {},
    recordId: '9680',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Rodda Paint\r10120 SW Park Way\rPortland, OR, 97225\r(503) 292-1515\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Rodda Paint',
      Site_Address: '10120 SW Park Way',
      City: 'Portland',
      Zip: '97225',
      Phone_Line: '(503) 292-1515',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.7814395,
      Latitude: 45.507060099999997,
      Pin_Code: '5 Gal',
      Location_ID: '6959',
      Site_ID_6: 'OR2424',
      County: 'Multnomah',
    },
    portalData: {},
    recordId: '9681',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Rodda Paint\r1211 Court St\rMedford, OR, 97501\r(541) 772-9711\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Rodda Paint',
      Site_Address: '1211 Court St',
      City: 'Medford',
      Zip: '97501',
      Phone_Line: '(541) 772-9711',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.88079020000001,
      Latitude: 42.3418864,
      Pin_Code: '5 Gal',
      Location_ID: '6995',
      Site_ID_6: 'OR2576',
      County: 'Jackson',
    },
    portalData: {},
    recordId: '9682',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Rodda Paint\r8614 SW Hall Blvd\rBeaverton, OR, 97008\r(503) 644-6188\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Rodda Paint',
      Site_Address: '8614 SW Hall Blvd',
      City: 'Beaverton',
      Zip: '97008',
      Phone_Line: '(503) 644-6188',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.7835434,
      Latitude: 45.458130400000002,
      Pin_Code: '20 Gal',
      Location_ID: '234',
      Site_ID_6: 'OR1054',
      County: 'Washington',
    },
    portalData: {},
    recordId: '9685',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Rodda Paint\r63007 Layton Ave\rBend, OR, 97701\r(541) 330-0370\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Rodda Paint',
      Site_Address: '63007 Layton Ave',
      City: 'Bend',
      Zip: '97701',
      Phone_Line: '(541) 330-0370',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -121.2918762,
      Latitude: 44.087860999999997,
      Pin_Code: '5 Gal',
      Location_ID: '189',
      Site_ID_6: 'OR1018',
      County: 'Deschutes',
    },
    portalData: {},
    recordId: '9686',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Rogue Valley Habitat ReStore\r2233 S Pacific Hwy\rMedford, OR, 97501\r(541) 773-9095\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Rogue Valley Habitat ReStore',
      Site_Address: '2233 S Pacific Hwy',
      City: 'Medford',
      Zip: '97501',
      Phone_Line: '(541) 773-9095',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.8526456,
      Latitude: 42.305425499999998,
      Pin_Code: 'Over 20 Reuse',
      Location_ID: '276',
      Site_ID_6: 'OR1102',
      County: 'Jackson',
    },
    portalData: {},
    recordId: '9687',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Santiam Elementary School Household Hazardous Waste Event\r450 SW Evergreen St\rMill City, OR, 97360\r(541) 575-0059\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Santiam Elementary School Household Hazardous Waste Event',
      Site_Address: '450 SW Evergreen St',
      City: 'Mill City',
      Zip: '97360',
      Phone_Line: '(541) 575-0059',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.4812634,
      Latitude: 44.750855999999999,
      Pin_Code: 'HHW',
      Location_ID: '5927',
      Site_ID_6: 'OR5028',
      County: 'Linn',
    },
    portalData: {},
    recordId: '9689',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Sawyers Ace Hardware\r500 E 3rd St\rThe Dalles, OR, 97058\r(541) 296-4814\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sawyers Ace Hardware',
      Site_Address: '500 E 3rd St',
      City: 'The Dalles',
      Zip: '97058',
      Phone_Line: '(541) 296-4814',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -121.17981880000001,
      Latitude: 45.598768399999997,
      Pin_Code: 'Over 20',
      Location_ID: '226',
      Site_ID_6: 'OR1036',
      County: 'Wasco',
    },
    portalData: {},
    recordId: '9690',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Wiliams\r61137 S Hwy 97\rBend, OR, 97702\r(541) 330-1714\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sherwin-Wiliams',
      Site_Address: '61137 S Hwy 97',
      City: 'Bend',
      Zip: '97702',
      Phone_Line: '(541) 330-1714',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -121.31676728227713,
      Latitude: 44.02039324350718,
      Pin_Code: '5 Gal',
      Location_ID: '6997',
      Site_ID_6: 'OR6184',
      County: 'Deschutes',
    },
    portalData: {},
    recordId: '9691',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Wiliams\r1390 N 1st St\rHermiston, OR, 97838\r(541) 567-2360\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sherwin-Wiliams',
      Site_Address: '1390 N 1st St',
      City: 'Hermiston',
      Zip: '97838',
      Phone_Line: '(541) 567-2360',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -119.289666,
      Latitude: 45.850857,
      Pin_Code: '5 Gal',
      Location_ID: '7106',
      Site_ID_6: 'OR6236',
      County: 'Umatilla',
    },
    portalData: {},
    recordId: '9692',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Wiliams\r30 NE Broadway St\rPortland, OR, 97232\r(503) 288-6477\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sherwin-Wiliams',
      Site_Address: '30 NE Broadway St',
      City: 'Portland',
      Zip: '97232',
      Phone_Line: '(503) 288-6477',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.6660136,
      Latitude: 45.534808300000002,
      Pin_Code: '5 Gal',
      Location_ID: '6858',
      Site_ID_6: 'OR2429',
      County: 'Multnomah',
    },
    portalData: {},
    recordId: '9693',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r19390 SW 90th Ct\rTualatin, OR, 97062\r(503) 691-0500\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '19390 SW 90th Ct',
      City: 'Tualatin',
      Zip: '97062',
      Phone_Line: '(503) 691-0500',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.76959119999999,
      Latitude: 45.379810399999997,
      Pin_Code: '5 Gal',
      Location_ID: '238',
      Site_ID_6: 'OR1055',
      County: 'Washington',
    },
    portalData: {},
    recordId: '9695',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r2835 SW 17th Pl\rRedmond, OR, 97756\r(541) 923-2224\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '2835 SW 17th Pl',
      City: 'Redmond',
      Zip: '97756',
      Phone_Line: '(541) 923-2224',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -121.18820030000001,
      Latitude: 44.250390199999998,
      Pin_Code: '5 Gal',
      Location_ID: '247',
      Site_ID_6: 'OR1065',
      County: 'Deschutes',
    },
    portalData: {},
    recordId: '9696',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r2220 Ashland St\rAshland, OR, 97520\r(541) 488-6238\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '2220 Ashland St',
      City: 'Ashland',
      Zip: '97520',
      Phone_Line: '(541) 488-6238',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.67377279999999,
      Latitude: 42.185088399999998,
      Pin_Code: '5 Gal',
      Location_ID: '253',
      Site_ID_6: 'OR1119',
      County: 'Jackson',
    },
    portalData: {},
    recordId: '9698',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r121 NE Harney St\rNewport, OR, 97365\r(541) 574-0774\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '121 NE Harney St',
      City: 'Newport',
      Zip: '97365',
      Phone_Line: '(541) 574-0774',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -124.04326090000001,
      Latitude: 44.637149800000003,
      Pin_Code: '5 Gal',
      Location_ID: '254',
      Site_ID_6: 'OR1072',
      County: 'Lincoln',
    },
    portalData: {},
    recordId: '9699',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r4525 S 6th St\rKlamath Falls, OR, 97603\r(541) 884-7704\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '4525 S 6th St',
      City: 'Klamath Falls',
      Zip: '97603',
      Phone_Line: '(541) 884-7704',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -121.733028,
      Latitude: 42.206831999999999,
      Pin_Code: '5 Gal',
      Location_ID: '197',
      Site_ID_6: 'OR1022',
      County: 'Klamath',
    },
    portalData: {},
    recordId: '9702',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r4596 Commercial St SE\rSalem, OR, 97302\r(503) 362-3729\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '4596 Commercial St SE',
      City: 'Salem',
      Zip: '97302',
      Phone_Line: '(503) 362-3729',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.032856,
      Latitude: 44.8879187,
      Pin_Code: '5 Gal',
      Location_ID: '208',
      Site_ID_6: 'OR1024',
      County: 'Marion',
    },
    portalData: {},
    recordId: '9704',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r1805 Lancaster Dr NE\rSalem, OR, 97305\r(503) 399-0911\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '1805 Lancaster Dr NE',
      City: 'Salem',
      Zip: '97305',
      Phone_Line: '(503) 399-0911',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.98489840000001,
      Latitude: 44.951464600000001,
      Pin_Code: '5 Gal',
      Location_ID: '209',
      Site_ID_6: 'OR1023',
      County: 'Marion',
    },
    portalData: {},
    recordId: '9705',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r2495 NW 9th St\rCorvallis, OR, 97330\r(541) 757-1619\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '2495 NW 9th St',
      City: 'Corvallis',
      Zip: '97330',
      Phone_Line: '(541) 757-1619',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.25220210000001,
      Latitude: 44.592535599999998,
      Pin_Code: '5 Gal',
      Location_ID: '214',
      Site_ID_6: 'OR1037',
      County: 'Benton',
    },
    portalData: {},
    recordId: '9707',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r4669 River Rd N\rKeizer, OR, 97303\r(503) 304-8898\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '4669 River Rd N',
      City: 'Keizer',
      Zip: '97303',
      Phone_Line: '(503) 304-8898',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.0270876,
      Latitude: 44.9917832,
      Pin_Code: '5 Gal',
      Location_ID: '218',
      Site_ID_6: 'OR1040',
      County: 'Marion',
    },
    portalData: {},
    recordId: '9709',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r1361 Center Dr\rMedford, OR, 97501\r(541) 779-0223\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '1361 Center Dr',
      City: 'Medford',
      Zip: '97501',
      Phone_Line: '(541) 779-0223',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.85362550000001,
      Latitude: 42.311192599999998,
      Pin_Code: '5 Gal',
      Location_ID: '4281',
      Site_ID_6: 'OR1120',
      County: 'Jackson',
    },
    portalData: {},
    recordId: '9711',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r2945 SE Santiam Hwy\rAlbany, OR, 97322\r(541) 926-0141\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '2945 SE Santiam Hwy',
      City: 'Albany',
      Zip: '97322',
      Phone_Line: '(541) 926-0141',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.06842519999999,
      Latitude: 44.631450899999997,
      Pin_Code: '5 Gal',
      Location_ID: '4282',
      Site_ID_6: 'OR1073',
      County: 'Linn',
    },
    portalData: {},
    recordId: '9712',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r12480 SW Center St\rBeaverton, OR, 97005\r(503) 643-4139\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '12480 SW Center St',
      City: 'Beaverton',
      Zip: '97005',
      Phone_Line: '(503) 643-4139',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.80532030000001,
      Latitude: 45.493433699999997,
      Pin_Code: '5 Gal',
      Location_ID: '4286',
      Site_ID_6: 'OR1121',
      County: 'Washington',
    },
    portalData: {},
    recordId: '9713',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r9745 SE Division St\rPortland, OR, 97266\r(503) 760-8233\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '9745 SE Division St',
      City: 'Portland',
      Zip: '97266',
      Phone_Line: '(503) 760-8233',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.5627412,
      Latitude: 45.504753600000001,
      Pin_Code: '5 Gal',
      Location_ID: '4652',
      Site_ID_6: 'OR1127',
      County: 'Multnomah',
    },
    portalData: {},
    recordId: '9716',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r521 Mount Hood St\rThe Dalles, OR, 97058\r(541) 296-4196\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '521 Mount Hood St',
      City: 'The Dalles',
      Zip: '97058',
      Phone_Line: '(541) 296-4196',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -121.19683759999999,
      Latitude: 45.604933099999997,
      Pin_Code: '5 Gal',
      Location_ID: '4359',
      Site_ID_6: 'OR1130',
      County: 'Wasco',
    },
    portalData: {},
    recordId: '9717',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r13555 NW Cornell Rd\rPortland, OR, 97229\r(503) 646-7064\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '13555 NW Cornell Rd',
      City: 'Portland',
      Zip: '97229',
      Phone_Line: '(503) 646-7064',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.8158286,
      Latitude: 45.527441400000001,
      Pin_Code: '5 Gal',
      Location_ID: '4360',
      Site_ID_6: 'OR1128',
      County: 'Multnomah',
    },
    portalData: {},
    recordId: '9718',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r125 NE Franklin Ave\rBend, OR, 97701\r(541) 389-2536\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '125 NE Franklin Ave',
      City: 'Bend',
      Zip: '97701',
      Phone_Line: '(541) 389-2536',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -121.305243,
      Latitude: 44.055629000000003,
      Pin_Code: '5 Gal',
      Location_ID: '4298',
      Site_ID_6: 'OR1039',
      County: 'Deschutes',
    },
    portalData: {},
    recordId: '9719',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r15659 Boones Ferry Rd\rLake Oswego, OR, 97035\r(503) 908-0959\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '15659 Boones Ferry Rd',
      City: 'Lake Oswego',
      Zip: '97035',
      Phone_Line: '(503) 908-0959',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.7175211,
      Latitude: 45.412645699999999,
      Pin_Code: '5 Gal',
      Location_ID: '4308',
      Site_ID_6: 'OR1131',
      County: 'Clackamas',
    },
    portalData: {},
    recordId: '9720',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r3390 W 11th Ave\rEugene, OR, 97402\r(541) 345-5130\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '3390 W 11th Ave',
      City: 'Eugene',
      Zip: '97402',
      Phone_Line: '(541) 345-5130',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.14261569999999,
      Latitude: 44.047569199999998,
      Pin_Code: '5 Gal',
      Location_ID: '4311',
      Site_ID_6: 'OR1122',
      County: 'Lane',
    },
    portalData: {},
    recordId: '9721',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r1520 N 18th St\rSpringfield, OR, 97477\r(541) 747-8592\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '1520 N 18th St',
      City: 'Springfield',
      Zip: '97477',
      Phone_Line: '(541) 747-8592',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.99870900000001,
      Latitude: 44.059112900000002,
      Pin_Code: '5 Gal',
      Location_ID: '4295',
      Site_ID_6: 'OR1126',
      County: 'Lane',
    },
    portalData: {},
    recordId: '9723',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sherwin-Williams\r18090 SE Mcloughlin Blvd\rMilwaukie, OR, 97267\r(503) 659-2091\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sherwin-Williams',
      Site_Address: '18090 SE Mcloughlin Blvd',
      City: 'Milwaukie',
      Zip: '97267',
      Phone_Line: '(503) 659-2091',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.6128652,
      Latitude: 45.392313399999999,
      Pin_Code: '5 Gal',
      Location_ID: '4292',
      Site_ID_6: 'OR1129',
      County: 'Clackamas',
    },
    portalData: {},
    recordId: '9724',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Smittys Ace Hardware\r1845 N 1st St\rHermiston, OR, 97838\r(541) 567-6816\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Smittys Ace Hardware',
      Site_Address: '1845 N 1st St',
      City: 'Hermiston',
      Zip: '97838',
      Phone_Line: '(541) 567-6816',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -119.29159869999999,
      Latitude: 45.855649499999998,
      Pin_Code: '20 Gal',
      Location_ID: '4022',
      Site_ID_6: 'OR1112',
      County: 'Umatilla',
    },
    portalData: {},
    recordId: '9726',
    modId: '72',
  },
  {
    fieldData: {
      Preview_Calc: 'Spray Transfer Station\rKahler Basin Rd\rSpray, OR, 97874\r(541) 350-7921\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Spray Transfer Station',
      Site_Address: 'Kahler Basin Rd',
      City: 'Spray',
      Zip: '97874',
      Phone_Line: '(541) 350-7921',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -119.790871,
      Latitude: 44.838141,
      Pin_Code: '20 Gal',
      Location_ID: '6498',
      Site_ID_6: 'OR6016',
      County: 'Wheeler',
    },
    portalData: {},
    recordId: '9727',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Springfield Eugene Habitat for Humanity\r1210 Oak Patch Rd\rEugene, OR, 97402\r(541) 344-4809\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Springfield Eugene Habitat for Humanity',
      Site_Address: '1210 Oak Patch Rd',
      City: 'Eugene',
      Zip: '97402',
      Phone_Line: '(541) 344-4809',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.1359363,
      Latitude: 44.047017599999997,
      Pin_Code: '20 Gal Reuse',
      Location_ID: '5836',
      Site_ID_6: 'OR1193',
      County: 'Lane',
    },
    portalData: {},
    recordId: '9728',
    modId: '72',
  },
  {
    fieldData: {
      Preview_Calc: 'Suburban Ace Hardware Inc\r3470 SW 185th Ave\rAloha, OR, 97006\r(503) 649-5555\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Suburban Ace Hardware Inc',
      Site_Address: '3470 SW 185th Ave',
      City: 'Aloha',
      Zip: '97006',
      Phone_Line: '(503) 649-5555',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.8664289,
      Latitude: 45.494646600000003,
      Pin_Code: '5 Gal',
      Location_ID: '186',
      Site_ID_6: 'OR1026',
      County: 'Washington',
    },
    portalData: {},
    recordId: '9730',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Sweet Home Household Hazardous Waste Event\r1325 18th Ave\rSweet Home, OR, 97386\r(541) 367-2535\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Sweet Home Household Hazardous Waste Event',
      Site_Address: '1325 18th Ave',
      City: 'Sweet Home',
      Zip: '97386',
      Phone_Line: '(541) 367-2535',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.722439,
      Latitude: 44.399877,
      Pin_Code: '20 Gal',
      Location_ID: '5992',
      Site_ID_6: 'OR5020',
      County: 'Linn',
    },
    portalData: {},
    recordId: '9731',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Swell City Hometown Paint\r1737 W Cascade Ave\rHood River, OR, 97031\r(541) 386-3773\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Swell City Hometown Paint',
      Site_Address: '1737 W Cascade Ave',
      City: 'Hood River',
      Zip: '97031',
      Phone_Line: '(541) 386-3773',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -121.529363,
      Latitude: 45.708941000000003,
      Pin_Code: '20 Gal',
      Location_ID: '277',
      Site_ID_6: 'OR1103',
      County: 'Hood River',
    },
    portalData: {},
    recordId: '9732',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Thatchers Ace Hardware\r2212 Island Ave\rLa Grande, OR, 97850\r(541) 605-0152\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Thatchers Ace Hardware',
      Site_Address: '2212 Island Ave',
      City: 'La Grande',
      Zip: '97850',
      Phone_Line: '(541) 605-0152',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -118.0772352,
      Latitude: 45.328520400000002,
      Pin_Code: 'Over 20',
      Location_ID: '262',
      Site_ID_6: 'OR1081',
      County: 'Union',
    },
    portalData: {},
    recordId: '9733',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Thatchers Ace Hardware\r2200 Resort St\rBaker City, OR, 97814\r(541) 523-3371\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Thatchers Ace Hardware',
      Site_Address: '2200 Resort St',
      City: 'Baker City',
      Zip: '97814',
      Phone_Line: '(541) 523-3371',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -117.8284077,
      Latitude: 44.779065099999997,
      Pin_Code: '5 Gal',
      Location_ID: '228',
      Site_ID_6: 'OR1042',
      County: 'Baker',
    },
    portalData: {},
    recordId: '9734',
    modId: '70',
  },
  {
    fieldData: {
      Preview_Calc: 'Thomas Creek Road Landfill\r23980 Thomas Creek Rd\rLakeview, OR, 97630\r(541) 417-1255\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Thomas Creek Road Landfill',
      Site_Address: '23980 Thomas Creek Rd',
      City: 'Lakeview',
      Zip: '97630',
      Phone_Line: '(541) 417-1255',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -120.364165,
      Latitude: 42.283239,
      Pin_Code: '20 Gal',
      Location_ID: '6786',
      Site_ID_6: 'OR6101',
      County: 'Lake',
    },
    portalData: {},
    recordId: '9735',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Thompsons Sanitary Service\r7450 NE Avery St\rNewport, OR, 97365\r(541) 265-7249\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Thompsons Sanitary Service',
      Site_Address: '7450 NE Avery St',
      City: 'Newport',
      Zip: '97365',
      Phone_Line: '(541) 265-7249',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -124.0610714,
      Latitude: 44.691601200000001,
      Pin_Code: '5 Gal',
      Location_ID: '283',
      Site_ID_6: 'OR1088',
      County: 'Lincoln',
    },
    portalData: {},
    recordId: '9736',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Tillamook County Household Hazardous Waste Facility\r1315 Ekloff Rd\rTillamook, OR, 97141\r(503) 815-3975\r\rWho Can Use This Program \rFor Households and Some Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the site for details and restrictions. Please do not bring empty, leaking or unlabeled containers. SOME BUSINESSES may use this program by appointment or on special days. Contact the program for details if your paint (or other hazardous waste) is from a business.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Tillamook County Household Hazardous Waste Facility',
      Site_Address: '1315 Ekloff Rd',
      City: 'Tillamook',
      Zip: '97141',
      Phone_Line: '(503) 815-3975',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the site for details and restrictions. Please do not bring empty, leaking or unlabeled containers. SOME BUSINESSES may use this program by appointment or on special days. Contact the program for details if your paint (or other hazardous waste) is from a business.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.85122800000001,
      Latitude: 45.413887000000003,
      Pin_Code: 'HHW',
      Location_ID: '3459',
      Site_ID_6: 'OR1104',
      County: 'Tillamook',
    },
    portalData: {},
    recordId: '9737',
    modId: '72',
  },
  {
    fieldData: {
      Preview_Calc: 'Tommys Paint Pot\r980 Lancaster Dr NE\rSalem, OR, 97301\r(503) 371-7027\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Tommys Paint Pot',
      Site_Address: '980 Lancaster Dr NE',
      City: 'Salem',
      Zip: '97301',
      Phone_Line: '(503) 371-7027',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.9823736,
      Latitude: 44.942734000000002,
      Pin_Code: '20 Gal',
      Location_ID: '4176',
      Site_ID_6: 'OR1113',
      County: 'Polk',
    },
    portalData: {},
    recordId: '9738',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Tommys Paint Pot\r1000 Conger St\rSte A\rEugene, OR, 97402\r(541) 683-5851\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Tommys Paint Pot',
      Site_Address: '1000 Conger St',
      City: 'Eugene',
      Zip: '97402',
      Phone_Line: '(541) 683-5851',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: 'Ste A',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.134387,
      Latitude: 44.048743999999999,
      Pin_Code: '20 Gal',
      Location_ID: '216',
      Site_ID_6: 'OR1043',
      County: 'Lane',
    },
    portalData: {},
    recordId: '9739',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Tri-County Household Hazardous Waste Event\r3rd Ave\rMosier, OR, 97040\r(541) 506-2636\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Tri-County Household Hazardous Waste Event',
      Site_Address: '3rd Ave',
      City: 'Mosier',
      Zip: '97040',
      Phone_Line: '(541) 506-2636',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -121.3952448,
      Latitude: 45.682763000000001,
      Pin_Code: 'HHW',
      Location_ID: '5995',
      Site_ID_6: 'OR5023',
      County: 'Wasco',
    },
    portalData: {},
    recordId: '9742',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Tri-County Household Hazardous Waste Event\rParkdale Fire Dept.\r4895 Baseline Dr\rParkdale, OR, 97041\r(541) 506-2636\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Tri-County Household Hazardous Waste Event',
      Site_Address: '4895 Baseline Dr',
      City: 'Parkdale',
      Zip: '97041',
      Phone_Line: '(541) 506-2636',
      URL: '',
      State: 'OR',
      Name2: 'Parkdale Fire Dept.',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -121.5905715,
      Latitude: 45.519353899999999,
      Pin_Code: 'HHW',
      Location_ID: '5970',
      Site_ID_6: 'OR5005',
      County: 'Hood River',
    },
    portalData: {},
    recordId: '9744',
    modId: '73',
  },
  {
    fieldData: {
      Preview_Calc: 'Tri-County Household Hazardous Waste Event\rPublic Works Dept.\r203 Deschutes Ave\rMaupin, OR, 97031\r(541) 506-2636\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Tri-County Household Hazardous Waste Event',
      Site_Address: '203 Deschutes Ave',
      City: 'Maupin',
      Zip: '97031',
      Phone_Line: '(541) 506-2636',
      URL: '',
      State: 'OR',
      Name2: 'Public Works Dept.',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -121.07708,
      Latitude: 45.176507999999998,
      Pin_Code: 'HHW',
      Location_ID: '5971',
      Site_ID_6: 'OR5006',
      County: 'Wasco',
    },
    portalData: {},
    recordId: '9745',
    modId: '72',
  },
  {
    fieldData: {
      Preview_Calc: 'Tri-County Household Hazardous Waste Event\r105 NE Herman Creek Dr\rCascade Locks, OR, 97014\r(541) 506-2636\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Tri-County Household Hazardous Waste Event',
      Site_Address: '105 NE Herman Creek Dr',
      City: 'Cascade Locks',
      Zip: '97014',
      Phone_Line: '(541) 506-2636',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -121.863617,
      Latitude: 45.680819999999997,
      Pin_Code: 'HHW',
      Location_ID: '5974',
      Site_ID_6: 'OR5011',
      County: 'Hood River',
    },
    portalData: {},
    recordId: '9748',
    modId: '68',
  },
  {
    fieldData: {
      Preview_Calc: 'Vernonia Hardware and Supply\r1026 Bridge St\rVernonia, OR, 97064\r(503) 429-5651\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Vernonia Hardware and Supply',
      Site_Address: '1026 Bridge St',
      City: 'Vernonia',
      Zip: '97064',
      Phone_Line: '(503) 429-5651',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.19753110000001,
      Latitude: 45.858900900000002,
      Pin_Code: '20 Gal',
      Location_ID: '227',
      Site_ID_6: 'OR1044',
      County: 'Columbia',
    },
    portalData: {},
    recordId: '9750',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Warm Springs Transfer Station\r6100 Dry Creek Trail Rd\rWarm Springs, OR, 97761\r(541) 553-3163\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Warm Springs Transfer Station',
      Site_Address: '6100 Dry Creek Trail Rd',
      City: 'Warm Springs',
      Zip: '97761',
      Phone_Line: '(541) 553-3163',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking. This site will accept empty containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -121.2551627,
      Latitude: 44.780434200000002,
      Pin_Code: '20 Gal',
      Location_ID: '4668',
      Site_ID_6: 'OR1150',
      County: 'Jefferson',
    },
    portalData: {},
    recordId: '9752',
    modId: '69',
  },
  {
    fieldData: {
      Preview_Calc: 'Washington County ReStore\r13475 SW Millikan Way\rBeaverton, OR, 97005\r(503) 906-3823\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Washington County ReStore',
      Site_Address: '13475 SW Millikan Way',
      City: 'Beaverton',
      Zip: '97005',
      Phone_Line: '(503) 906-3823',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.8153942,
      Latitude: 45.492448799999998,
      Pin_Code: '20 Gal Reuse',
      Location_ID: '268',
      Site_ID_6: 'OR1091',
      County: 'Washington',
    },
    portalData: {},
    recordId: '9753',
    modId: '72',
  },
  {
    fieldData: {
      Preview_Calc: 'Welches Mountain Building Supply\r67250 E Hwy 26\rWelches, OR, 97067\r(503) 622-3000\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Welches Mountain Building Supply',
      Site_Address: '67250 E Hwy 26',
      City: 'Welches',
      Zip: '97067',
      Phone_Line: '(503) 622-3000',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -121.9729471,
      Latitude: 45.351008700000001,
      Pin_Code: '20 Gal',
      Location_ID: '3457',
      Site_ID_6: 'OR1107',
      County: 'Clackamas',
    },
    portalData: {},
    recordId: '9755',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'West Tuality Habitat for Humanity\r3731 Pacific Ave\rForest Grove, OR, 97116\r(503) 357-1201\r\rWho Can Use This Program \rFor Households and Businesses\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.\r',
      Count: 171,
      Active: 'Y',
      Name: 'West Tuality Habitat for Humanity',
      Site_Address: '3731 Pacific Ave',
      City: 'Forest Grove',
      Zip: '97116',
      Phone_Line: '(503) 357-1201',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site does not accept aerosols (spray paint or other spray products),\rcontainers without original labels, or containers that are leaking or empty.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -123.081096,
      Latitude: 45.520758200000003,
      Pin_Code: 'Over 20 Reuse',
      Location_ID: '6061',
      Site_ID_6: 'OR1192',
      County: 'Washington',
    },
    portalData: {},
    recordId: '9756',
    modId: '71',
  },
  {
    fieldData: {
      Preview_Calc: 'Yamhill County HHW Event\r2904 Wynooski Rd\rNewberg, OR, 97132\r(503) 434-7445\r\rWho Can Use This Program \rFor Households Only (No Businesses)\r\rInformation\rThis site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.\r',
      Count: 171,
      Active: 'Y',
      Name: 'Yamhill County HHW Event',
      Site_Address: '2904 Wynooski Rd',
      City: 'Newberg',
      Zip: '97132',
      Phone_Line: '(503) 434-7445',
      URL: '',
      State: 'OR',
      Name2: '',
      Address2: '',
      For_Type_to_Display: '',
      'Hours to Display': '',
      Comment1: '',
      Comment2: '',
      Comment3: '',
      Comment4: 'This site accepts latex and oil-based house paint, stains and varnish in containers up to 5 gallons in size. This site also takes most other household hazardous waste -- contact the\rsite for details and restrictions. Please do not bring empty, leaking or unlabeled containers.',
      Reuse_Statement: '',
      Visit_PaintCare_Statement: '',
      'Pin_Color::Pin_Image': '',
      Longitude: -122.94855200000001,
      Latitude: 45.283909999999999,
      Pin_Code: 'HHW',
      Location_ID: '5988',
      Site_ID_6: 'OR5010',
      County: 'Yamhill',
    },
    portalData: {},
    recordId: '9757',
    modId: '68',
  },
];

export default sites;
